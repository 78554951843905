import { useState } from "react";
import { LuDelete } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import SucessPopups from "./SucessPopups";
import { useAppDispatch } from "../Redux/Hooks";

import toast from "react-hot-toast";
import { deleteUserAccount } from "../Slices/UserSlice";
import { useNavigate } from "react-router-dom";

interface propsType {
   setDeleteUser:any
   data:any;
   setRefetch:any;
   refetch:any;

}

const DeleteUserPopup: React.FC<propsType> = ({
   setDeleteUser,
   data,
   setRefetch,
   refetch

}) => {
  const dispatch = useAppDispatch();
  const [popup, setPopup] = useState(false);
  const nav=useNavigate();

  const DeleteFunc = () => {
    const payload = {
      _id: data?._id,
      name: data?.name,
      email: data?.email,
      userType: data?.userType,
      userStatus:data?.userStatus,
    };
    dispatch(deleteUserAccount(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          // toast("User is Delete Successfully",{
          //   position:"top-right"
          // })
          setRefetch(refetch+1)
          nav("/user")
          // setPopup(true);
        }
      })
      .catch((error) => {
        toast(error, {
          position: "top-right",
        });
        console.log({ error });
      });
  };

  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[999]  bg-[#00000040]">
      <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
      <div
        className=" bg-[white] flex w-[350px] h-[220px] md:w-[320px]  rounded-[10px] z-[100] flex-col p-6 md:p-4"
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <div className="flex  w-full h-[20px] justify-between items-center py-4 ">
          <div className=" flex gap-2 items-center">
            <LuDelete color={"#FF2F2F"} size={20} />
            <span className="font-semibold">Delete User?</span>
          </div>
          <span>
            <RxCross2
              onClick={() => {
               setDeleteUser(false);
              }}
            />
          </span>
        </div>
        <div className="text-center flex flex-col w-full py-4  ">
          <span className="pl-4 md:pl-0">
            This User will be deleted permanently.
          </span>
          <span> Are you sure you want to proceed?</span>
        </div>
        <div className="w-full flex gap-4 justify-center  py-2">
          <button
            onClick={() => setDeleteUser(false)}
            className="px-8 py-[6px] border border-[#A0A0A0] rounded-[8px]"
          >
            Cancel
          </button>
          <button
            onClick={() => DeleteFunc()}
            className="px-8 py-[6px] border-none text-white bg-[#3D5765] font-semibold rounded-[8px]"
            style={{ boxShadow: " 0px 4px 10px 0px #BAC9DB" }}
          >
            Delete
          </button>
        </div>
      </div>
      {popup && (
        <SucessPopups setPopup={setPopup} setDeleteTask={setDeleteUser} />
      )}
    </div>
  );
};

export default DeleteUserPopup;
