import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import toast, { Toaster } from 'react-hot-toast';
import { persistor, store } from './Redux/Store';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: 'lightblue',
            },
          },
          error: {
            style: {
              background: 'pink',
            },
          },
        }}
      />
      <App />
    </PersistGate>
  </Provider>
);

