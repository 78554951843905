import { SVGProps } from "react"

const AddRegisterSVG = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#B8B9C8"
      d="M10 3c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4Zm7 17 1.8 1.77c.5.5 1.2.1 1.2-.49V17l2.8-3.4A1 1 0 0 0 22 12h-7c-.8 0-1.3 1-.8 1.6L17 17v3Zm-2-2.3-2.3-2.8c-.4-.5-.6-1.1-.6-1.7-.7-.2-1.4-.2-2.1-.2-4.4 0-8 1.8-8 4v2h13v-1.3Z"
    />
  </svg>
)
export default AddRegisterSVG
