
import NewHeader from '../Components/Header Component/NewHeader'
import AddLabCase from '../Components/LabCases/AddLabCase'

const AddLabCasesScreen = ({ refetch, setRefetch }: any) => {
  return (
   <div className="h-full w-full flex  ">
      <div className="h-full w-full flex flex-col gap-2 py-4 px-10 md:px-6">
        <NewHeader heading="Add Lab Case" />
        <AddLabCase refetch={refetch} setRefetch={setRefetch} />
      </div>
    </div>
  )
}

export default AddLabCasesScreen