import { useNavigate } from "react-router-dom";

const Folder = () => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-[#616E7D] rounded-lg h-32 flex flex-col justify-between cursor-pointer "
      style={{
        width: "calc(15% - 18px)",
      }}
      onClick={() => navigate("/document/11")}
    >
      <div className="w-full flex ">
        <div className="w-[30%]"></div>
        <div className="w-[71%]">
          <div className="w-full bg-[#EDF1F6] h-[30px] pt-2 relative ">
            <div className="w-[90%] bg-[#AAD4C8] h-full rounded-tr-lg"></div>
            <div className="absolute h-[110%] w-4 bg-[#616E7D] top-[2px] -rotate-[20deg] -left-[9px]"></div>
          </div>
          <div className="flex flex-col ">
          <p className="text-white text-[10px] pt-2 text-center rounded-tr-lg ">
            User Name
          </p>
          <p className="text-[#B8B9C8] text-[10px] text-center rounded-tr-lg ">
          Designation
          </p>

          </div>
        </div>
      </div>
      <div className="mb-5 w-full h-2 bg-[#AAD4C8]"></div>
    </div>
  );
};

export default Folder;
