import React, { useState } from "react";
import SubHeader from "../Components/Header Component/SubHeader";
import ViewSingleTask from "../Components/TaskComponents/ViewSingleTask";
import TodoTask from "../Components/TaskComponents/TodoTask";
import InprogressTask from "../Components/TaskComponents/InprogressTask";
import CompletedTask from "../Components/TaskComponents/CompletedTask";
import RespoAllTask from "../Components/ResponsiveComponents/RespoAllTask";
import Loader from "../assets/image/svg/Loader";

interface PropsType {
  todo: any;
  inprogress: any;
  completed: any;
  windowWidth: any;
  showScreen: any;
  setShowScreen: any;
  refetch: any;
  setRefetch: any;
  loader: any;
}

const AllTaskScreen: React.FC<PropsType> = ({
  todo,
  inprogress,
  completed,
  windowWidth,
  showScreen,
  setShowScreen,
  refetch,
  setRefetch,
  loader,
}) => {
  const [viewTask, setViewTask] = useState(false);
  const [handleOpen, setHandleOpen] = useState("");
  const [taskDetail, setTaskDetail] = useState<any>({});
  const [size, setSize] = useState<any>("medium");
  const [myTask, setMyTask] = useState<any>("false");

  const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSize(event.target.value);
  };

  return (
    <>
      {windowWidth < 700 ? (
        <RespoAllTask // this responsive component and its important
          button={"Add Task"}
          todo={todo}
          size={size}
          myTask={myTask}
          inprogress={inprogress}
          completed={completed}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
          setTaskDetail={setTaskDetail}
          setViewTask={setViewTask}
          taskDetail={taskDetail}
          viewTask={viewTask}
          setRefetch={setRefetch}
          refetch={refetch}
          name={"All Tasks"}
        />
      ) : (
        <div className=" w-[100%] h-[100%] flex  px-5">
          <div className="w-full h-full  ">
            <SubHeader
              heading="All Tasks"
              button="Add Task"
              setHandleOpen={setHandleOpen}
            />

            <div className="w-full items-center flex justify-end mt-3 pr-5 ">
              <div className="pr-5">
                <label className="mr-2 text-[15px]">Select Task:</label>
                <select
                  id="size"
                  value={myTask}
                  onChange={(e) => setMyTask(e.target.value)}
                  className="px-2  text-[12px] py-1 border border-none rounded"
                >
                  <option value={"true"}>My Task</option>
                  <option value={"false"}>All Task</option>
                </select>
              </div>
              <label className="mr-2  text-[15px]">Select Cart Size:</label>
              <select
                id="size"
                value={size}
                onChange={handleSizeChange}
                className="px-2  text-[12px] py-1 border rounded"
              >
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </select>
            </div>

            <div
              className=" w-full  flex gap-2  py-4  justify-between  "
              style={{ height: "calc(100% - 48px)" }}
            >
              <TodoTask // this component is todo task
                todo={todo}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
              />

              <div className="h-full border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <InprogressTask // this component is inprogress task
                inprogress={inprogress}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
              />

              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <CompletedTask // this component is completed task
                completed={completed}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
              />
            </div>
          </div>
          {loader && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
              <div className="bg-[#00000047] backdrop-blur-sm z-[99] w-full h-full absolute top-0 left-0" />
              <div className="relative z-[999]">
                <Loader />
              </div>
            </div>
          )}

          {viewTask && (
            <ViewSingleTask // this component is used to view details of task
              setViewTask={setViewTask}
              taskDetail={taskDetail}
              setRefetch={setRefetch}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AllTaskScreen;
