import React, { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";

import ArrowSVG from "../../assets/image/svg/ArrowSVG";
import CartTask from "./CartTask";
import { IoMdArrowBack } from "react-icons/io";
import { useAppSelector } from "../../Redux/Hooks";
import HighStatusSvg from "../../assets/image/svg/HighStatusSvg";

interface PropsType {
  todo: any;
  setTaskDetail: any;
  setViewTask: any;
  setView?: any;
  view?: any;
  setShowTask?: any;
  type?: any;
  setSize: any;
  size: string;
  myTask: any;
}

const NewReward: React.FC<PropsType> = ({
  todo,
  setShowTask,
  setTaskDetail,
  setViewTask,
  type,
  setSize,
  size,
  myTask,
}) => {
  const { user } = useAppSelector((state) => state.user);

  const calcLenght = () => {
    if (myTask === "true") {
      const tempArray = todo?.filter((item: any) =>
        item?.assign_to?.includes(user?._id)
      );
      return tempArray?.length;
    } else {
      return todo?.length;
    }
  };

  return (
    <>
      <div className="mt-4 lg:hidden">
        <IoMdArrowBack size={25} onClick={() => setShowTask("")} />
      </div>
      <Droppable droppableId="todoList">
        {(provided) => (
          <div
            className="w-1/3 h-full bg-[#E2E8F0] p-2 rounded-[15px] md:w-full  md:mt-[20px] "
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="  h-full flex  justify-center flex-wrap overflow-auto  ">
              <div className="w-full h-full flex flex-col gap-4  px-2 py-3   ">
                <div className="w-full flex px-2 ">
                  <span
                    className="text-[16px] font-semibold text-center px-5 py-1 bg-[#D8F0CF] rounded-[7px] text-[#3BB311] "
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    New Dispute
                  </span>
                </div>
                <div className="flex flex-col  py-1 px-2">
                  <span className="font-medium text-[14px]">New Lead</span>
                  <span className="text-[#505050] text-[13px]">
                    The lead are create by team members.
                  </span>
                </div>
                <div className="h-full flex flex-col gap-2 w-full overflow-auto ">
                  <CartTask
                    setViewTask={setViewTask}
                    setTaskDetail={setTaskDetail}
                    icon={<HighStatusSvg />}
                    type={type}
                    size={size}
                  />
                </div>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default NewReward;
