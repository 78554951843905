import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { UpdateUser } from "../../Slices/UserSlice";
import toast from "react-hot-toast";
import CrossSVG from "../../assets/image/svg/CrossSVG";
import { Form, Formik } from "formik";
import { updateUserInfoSchema } from "../../Validations/FormikValidation";
import InputField from "../Field Components/InputField";
import SucessPopups from "../../Popups/SucessPopups";
import Loader from "../../assets/image/svg/Loader";

interface propsType {
  setProf: any;
}
const UpdateProfile: React.FC<propsType> = ({ setProf }) => {
  const [update, setUpdate] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const handleUpdateFunc = (userName: any, email: any) => {
    setLoader(true);
    const payload = {
      _id: user?._id,
      name: userName,
      email: email,
      userType: user?.userType,
      userStatus: toggle ? "active" : "inactive",
    };
    dispatch(UpdateUser(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          
          setLoader(false);
          toast.success(res?.msg, {
            position: "top-right",
          });
          setUpdate(true);
          setProf(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error, {
          position: "top-right",
        });
      });
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (user?.userStatus && user?.userStatus === "active") {
      setToggle(true);
    } else if (user?.userStatus && user?.userStatus === "inactive") {
      setToggle(false);
    }
  }, []);
  
  return (
    <div className="flex  justify-center items-center h-full fixed top-0 left-0 w-full z-[99] bg-[#00000040]">
      <div
        onClick={() => setProf(false)}
        className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"
      ></div>
      <div className="bg-white h-auto w-[33%] md:w-[90%]  z-20 rounded-[20px] px-[40px] py-[40px] relative ">
        <div
          onClick={() => setProf(false)}
          className="flex items-center justify-end w-full cursor-pointer"
        >
          {/* <RxCross1 color="black" size={"15px"} /> */}
          <CrossSVG />
        </div>
        <div className="flex flex-col pb-4 pt-2">
          <p className="font-semibold text-[18px] ">Account Details</p>
          <div className="text-[12px] text-[#B0B0B0]">
            View and update your personal information here.
          </div>
        </div>

        <Formik
          initialValues={{
            userName: user?.name ? user?.name : "",
            email: user?.email ? user?.email : "",
          }}
          validationSchema={updateUserInfoSchema}
          onSubmit={(values) => {
            handleUpdateFunc(values.userName, values.email);
           
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }: any) => (
            <div className="flex w-full justify-center  ">
              <Form
                onSubmit={handleSubmit}
                className=" flex flex-col gap-4 w-full"
              >
                <label className="font-semibold text-[14px]">
                  Username<span className="text-[#FF2F2F]">*</span>
                </label>
                <InputField
                  className="w-full bg-white rounded-lg border border-[#DADADA]"
                  fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                  placeholderStyle={{ top: "8px", left: "18px" }}
                  type="name"
                  name="userName"
                  placeholder="Enter Username"
                  value={values?.userName}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.userName ? errors.userName : undefined}
                />

                <label className="font-semibold text-[14px]">
                  Email <span className="text-[#FF2F2F]">*</span>
                </label>
                <InputField
                  className="w-full bg-white rounded-lg border border-[#DADADA]"
                  fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                  placeholderStyle={{ top: "8px", left: "18px" }}
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  value={values?.email}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.email ? errors.email : undefined}
                />
                <div className="flex flex-col py-2">
                  <span className="font-medium text-[14px]">
                    User Status{" "}
                    <span className="text-[#CCCCCC]">(Active/Inactive) </span>
                    <span className="text-[#FF2F2F]">*</span>{" "}
                  </span>

                  <div
                    className={`slide-button ${toggle ? "on" : "off"}`}
                    style={{ backgroundColor: toggle ? "#6EC350" : "#CCCCCC" }}
                    onClick={handleToggle}
                  >
                    <div className="slider" />
                  </div>
                </div>

                <div className="flex justify-center gap-4 mt-1 ">
                  <button
                    onClick={() => setProf(false)}
                    type="button"
                    className="w-1/2 py-2 text-[16px] border border-[#A0A0A0] rounded-[10px] font-normal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="w-1/2 text-center rounded-[10px] text-[16px] bg-[#3D5765] font-normal text-white"
                  >
                    {loader ? (
                      <div className="flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
      {update && <SucessPopups setPopup={setUpdate} />}
    </div>
  );
};

export default UpdateProfile;
