import { SVGProps } from "react";

const CalenderSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1369_2364)" fill="#D65252">
      <path d="M8.959 1.988H7.847v.833a.61.61 0 11-1.222 0v-.833H3.39v.833a.611.611 0 01-1.222 0v-.833H1.056a.494.494 0 00-.5.503v6.216a.494.494 0 00.486.503h7.917a.494.494 0 00.486-.503V2.491a.494.494 0 00-.486-.503zM2.778 7.543h-.556v-.555h.556v.555zm0-1.389h-.556V5.6h.556v.555zm0-1.388h-.556V4.21h.556v.556zm1.667 2.777h-.556v-.555h.556v.555zm0-1.389h-.556V5.6h.556v.555zm0-1.388h-.556V4.21h.556v.556zM6.11 7.543h-.555v-.555h.555v.555zm0-1.389h-.555V5.6h.555v.555zm0-1.388h-.555V4.21h.555v.556zm1.667 2.777h-.556v-.555h.556v.555zm0-1.389h-.556V5.6h.556v.555zm0-1.388h-.556V4.21h.556v.556z" />
      <path d="M2.778 3.1a.278.278 0 00.278-.279V1.155a.278.278 0 00-.556 0V2.82a.278.278 0 00.278.278zM7.222 3.1A.278.278 0 007.5 2.82V1.155a.278.278 0 10-.556 0V2.82a.278.278 0 00.278.278z" />
    </g>
    <defs>
      <clipPath id="clip0_1369_2364">
        <path fill="#fff" transform="translate(0 .321)" d="M0 0H10V10H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CalenderSVG;
