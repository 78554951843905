import { SVGProps } from "react"

const LowStatusSvg = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#6EC350"
        fillRule="evenodd"
        d="M9.716 17.329a1.012 1.012 0 0 1-1.432 0L.67 9.715a1.013 1.013 0 0 1 0-1.433L8.284.67a1.013 1.013 0 0 1 1.432 0l7.613 7.612a1.012 1.012 0 0 1 0 1.433l-7.613 7.614Zm-.319-4.183a.564.564 0 0 1-.803-.008l-3-3.125a.562.562 0 1 1 .812-.78l2.031 2.117V5.998a.563.563 0 0 1 1.125 0v5.393l2.04-2.04a.563.563 0 1 1 .795.795l-3 3Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default LowStatusSvg
