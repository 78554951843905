import React, { useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { TbBellFilled } from "react-icons/tb";
import MyProfile from "./MyProfile";
import Notification from "./Notification";

interface PropsType {
  heading?: string;
  setShowScreen?: React.Dispatch<React.SetStateAction<string>>;
}

const NewHeader: React.FC<PropsType> = ({ heading, setShowScreen }) => {
  // const [profile, setProfile] = useState(false);
  // const [notification, setNotification] = useState(false);
  // const [pass, setPass] = useState(false);
  // const [prof, setProf] = useState(false);
  return (
    <>
      <div className="w-[100%]  z-50  flex items-center  top-0  justify-between px-2 py-4 ">
        <div className="w-[100%] h-[40px]  flex  items-center justify-between  rounded-[9px] ">
          <div>
            {/*            
          
            <span onClick={()=>setShowScreen && setShowScreen("sidebar")} className="min-771:hidden"><HamIconSVG/></span> */}
            <span className="text-[24px] font-medium text-center">
              {heading}
            </span>
          </div>

          {/* <div className="flex gap-10 md:gap-[20px] md:items-center ">
            <TbBellFilled
              color="#3D5765"
              size={30}
              onClick={() => setNotification(true)}
            />
            <span className="min-771:hidden">
              <BsPersonCircle color="#3D5765" size={45} 
              onClick={()=>setProfile(true)}
              
              />
            </span>
            <span className="max-771:hidden">
              <BsPersonCircle color="#3D5765" size={30} 
               onClick={()=>setProfile(true)}
              />
            </span>
          </div> */}
        </div>
        {/* <div className="h-[100%] ">
          {notification && (
            <Notification
              setNotification={setNotification}
              notification={notification}
            />
          )}
          {profile && (
            <MyProfile
              profile={profile}
              setPass={setPass}
              setProfile={setProfile}
              setProf={setProf}
            />
          )}
       
        </div> */}
      </div>
    </>
  );
};

export default NewHeader;
