import React, { useEffect, useState } from "react";
import { addComment, deleteComment } from "../Slices/TaskSlice";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks";
import toast from "react-hot-toast";
import { MdAddComment } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import moment from "moment";

interface propsType {
  length: number;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  data: any;
  comment: any;
  setComment: any;
  refetch:any;
  setRefetch:any;
}

const CommentSection: React.FC<propsType> = ({
  values,
  handleChange,
  data,
  setComment,
  comment,
  refetch,
  setRefetch
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state?.user?.user);
  const [inputValue, setInputValue] = useState("");

  useEffect(()=>{
    setComment(values);
  },[])

  const handleComment = () => {
    
    const payload = {
      comment: inputValue ? inputValue.trim() : "",
      task_id: data[0]?._id,
      user_id: user?._id,
    };
    dispatch(addComment(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setComment((prev: any) => [...prev, res?.commentData]);
          setInputValue("");
          setRefetch(refetch+1)
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      
      });
  };

  const handleRemove = (value: any) => {
   
    const payload = {
      comment_id: value?._id,
    };
    dispatch(deleteComment(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          const tempComment = comment.filter((item: any, index: number) => {
            return item !== value;
          });
          setComment(tempComment);
          setRefetch(refetch+1)
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error?.msg);
      });
  };


  return (
    <>
      <div className="w-full ">
        <span className="font-semibold">Comment Section</span>
        <div className="flex gap-4 py-3 md:items-center ">
          <span className="text-[#909090]">Showing:</span>
          <span className="text-[#426D85] md:text-[12px]">All Comments</span>
        </div>

        <div className="flex w-full gap-4 py-2 items-center">
          <div className="w-[35px] h-[35px] flex justify-center items-center text-white text-[18px] border-none rounded-full bg-[#3D5765] p-2 ">
            {user?.name[0]?.toUpperCase() + "" + user?.name[1]}
          </div>
          <div className="border w-full p-2 border-[#A0A0A0] rounded-[10px]">
            <div className="flex mt-2 w-full ">
              <div className="flex relative mb-2 w-full ">
                <input
                  className="w-full h-[20px]   pl-5 outline-none  pr-3 bg-none relative z-[1]"
                  type="text"
                  name="comment"
                  value={inputValue}
                  placeholder="Add a Comment..."
                  id="comment"
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>
              <span className="font-medium " onClick={handleComment}>
                <MdAddComment size={25} color={"#4278f5"} />
              </span>
            </div>
          </div>
        </div>
        {comment.length !== 0 &&
          comment.map((item: any, index: number) => {

            
            return (
              <div key={index} className="flex flex-col gap-4  py-4">
                <div className="flex gap-4">
                  <div className="w-[35px] h-[35px] flex justify-center items-center rounded-full text-white bg-[#426D85]">
                    {user?.name[0]?.toUpperCase() + "" + user?.name[1]}
                  </div>
                  <div className="flex flex-col w-full">
                    <span className="font-semibold text-[14px]">
                      {user?.name?.toUpperCase()}
                    </span>
                    <div className="flex items-center justify-between">
                      <span className="font-normal">{item?.comment}</span>
                      <span onClick={() => handleRemove(item)}>
                        <MdDelete color={"#DF0000"} size={20} />
                      </span>
                    </div>
                    <p className="text-[#909090] text-[10px]">
                      Commented :{" "}
                      <span>
                        {moment(item?.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
       
      </div>
    </>
  );
};

export default CommentSection;
