import LoginForm from "../Components/Login component/LoginForm";
import WelcomeLogin from "../Components/Login component/WelcomeLogin";
import wavy from "../assets/image/Wavy.jpg";
import wavy2 from "../assets/image/Wavy2.jpg";

const Login = () => {
  return (
    <div
      className=" flex h-[100vh] w-[100%] justify-center items-center border"
      style={{
        backgroundImage: `url(${wavy}),url(${wavy2})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left top , left bottom",
        backgroundSize: "350px 350px , 340px 201px ",
        objectFit: "contain",
      }}
    >
      <WelcomeLogin />
      <LoginForm />
    </div>
  );
};

export default Login;
