import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { useOutSideCheck } from "../customize state/useOutSideCheck";
import MultiSelectPopup from "./MultiSelectPopup";


interface FieldPropsType {
  className?: string;
  fieldClass?: string;
  fieldDisable?: boolean;
  name: string;
  value: any[];
  dataType?: any;
  options: object[];
  errors?: any;
  setFieldValue?: any;
  touched?: any;
}

const MultiSelectField: React.FC<FieldPropsType> = ({
  className,
  fieldClass,
  name,
  value,
  dataType = "string",
  options,
  errors,
  touched,
  setFieldValue,
  fieldDisable = false,
}) => {
  const [selectValue, setSelectValue] = useState([...value]);
  const [popupShow, setPopupShow] = useState(false);
  const wrapperRef = useRef(null);
  useOutSideCheck(wrapperRef, setPopupShow, popupShow);

  const handleClick = (item: any) => {
    const filterData = selectValue.filter((ele) => item !== ele);
    setSelectValue(filterData);
    setFieldValue(name, filterData);
  };

  useEffect(() => {
    if (value) {
      setSelectValue([...value]);
    }
  }, [value]);

  return (
    <div className={className + " relative "} ref={wrapperRef}>
      <div
        className={
          fieldClass +
          " flex flex-row gap-1 items-center justify-between  border-[#9A9A9A] rounded-[6px]"
        }
        onClick={() => !fieldDisable && setPopupShow(!popupShow)}
      >
        <div className="flex flex-row gap-1 flex-wrap">
          {selectValue?.map((item: any, index) => (
            <span
              key={index}
              className={
                "flex flex-row items-center gap-1 bg-[#ECECEC] rounded-xl text-[#8A8A8A] text-[12px] px-2 " +
                `${fieldDisable && "text-[#8a8a8a]"}`
              }
            >
              {dataType === "string" ? item : item?.name}

              <IoMdClose
                color="black"
                size={14}
                onClick={() => handleClick(item)}
              />
            </span>
          ))}
        </div>
        <div>
          <IoIosArrowDown />
        </div>
      </div>
      {popupShow && !fieldDisable && (
        <MultiSelectPopup
          data={options}
          selectValue={selectValue}
          dataType={dataType}
          setSelectValue={setSelectValue}
          setFieldValue={setFieldValue}
          name={name}
        />
      )}

      <div className="text-[#FF5050] text-xs absolute capitalize font-medium top-6 left-4 pt-1">
        {errors && touched && typeof errors === "string" && errors}
      </div>
    </div>
  );
};

export default MultiSelectField;
