import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, setAuthToken, uploadInstance } from "../utils/AxiosInstance";

interface userState {
    task: [] ;
    loading: boolean;
    error: string;
}

interface TaskData {
    title: string;
    description: string,
    attached_files: [string],
    commentsData: [string],
    assign_to: [string],
    owner: string,
    lead: string,
    priority: string,
    deadline: string,
    status: string,
    taskType: string,
    
}

const initialState: userState = {
    task: [],
    loading: false,
    error: "",
};

export const getAllTasks = createAsyncThunk(
    "getAllTasks",
    async (data: object, { rejectWithValue }) => {
        try {
            const url = "/task/getall";
            const requestBody = data;
            const response = await instance.post(url, requestBody);
            
            return response?.data;
        } catch (error: any) {
            throw rejectWithValue(error?.response?.data);
        }
    }
);

export const createTask = createAsyncThunk(
    "createTask",
    async (data: object, { rejectWithValue }) => {
        try {
            const url = "/task/create";
            const requestBody = data;
            const response = await instance.post(url, requestBody);
            console.log({ response })
            return response?.data;
        } catch (error: any) {
            throw rejectWithValue(error?.response?.data);
        }
    }
);



export const createLabCase = createAsyncThunk(
    "createLabCase",
    async(data:object,{rejectWithValue})=>{
        try{
            const url="/libcase/create";
            const requestBody=data;
            const response=await instance.post(url,requestBody);
            console.log({response});
            return response?.data;
        }catch (error: any) {
            throw rejectWithValue(error?.response?.data);
        }
    }
);


export const deleteTask=createAsyncThunk(
    "deleteTask",
    async(data:object,{rejectWithValue})=>{
        try{
            const url="/task/delete";
            const requestBody=data;
            const response=await instance.post(url,requestBody);
            console.log({response});
            return response?.data;
        }catch(error:any){
            throw rejectWithValue(error?.response?.data);
        }
    }
)
 
export const addComment=createAsyncThunk(
    "addComment",
    async(data:object,{rejectWithValue})=>{
        try{
            const url="/task/addcomment"
            const requestBody=data;
            const response=await instance.post(url,requestBody);
            console.log({response});
            return response?.data;
        }catch(error:any){
            throw rejectWithValue(error?.response?.data); 
        }

    }
)

export const deleteComment=createAsyncThunk(
    "deleteComment",
    async(data:object,{rejectWithValue})=>{
        try{
            const url="/task/deletecomment"
            const requestBody=data;
            const response=await instance.post(url,requestBody)
            return response?.data;
        }catch(error:any){
            throw rejectWithValue(error?.response?.data);
        }
    }
)

export const uploadDocument = createAsyncThunk(
    "uploadDocument",
    async (data: object, { rejectWithValue }) => {
      try {
        const url = "/utils/upload";
        const requestBody = data;
        const response = await uploadInstance.post(url, requestBody);
        if (response?.data?.status) {
          console.log(response, "------------response uploadDocument");
        }
        return response?.data;
      } catch (error: any) {
        console.log("---------->uploadDocument error", error);
        throw rejectWithValue(error?.response?.data);
      }
    }
  );

  export const getAllLabCases=createAsyncThunk(
    "getAllLabCases",
    async(data:object,{rejectWithValue})=>{
        try{
            const url="/libcase/getall";
            const requestBody=data;
            const response=await instance.post(url,requestBody);
            if(response?.data?.status){
                console.log(response,"--------------------response is here")
            }
            return response?.data;
        }catch(error:any){
            console.log("---------------------->error is here",error);
            throw rejectWithValue(error?.response?.data);
        }
    }
  )

  export const updateTask=createAsyncThunk(
    "updateTask",
    async(data:object,{rejectWithValue})=>{
        try{
            const url="/task/update";
            const requestBody=data;
            const response=await instance.post(url,requestBody);
            if(response?.data?.status){
                console.log(response,"------------------response updateTask");
            }
            return response?.data;
        }catch(error:any){
            console.log("----------------->updateTask error",error);
            throw rejectWithValue(error?.response?.data);
        }
    }
  )

const taskSlice = createSlice({
    name: "task",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAllTasks.pending, (state) => {
                return { ...state, loading: true };
            })
            .addCase(getAllTasks.fulfilled, (state, action) => {
                // console.log(action?.payload, "==============");
                return {
                    ...state,
                    loading: false,
                    task: action.payload?.taskData,
                };
            })
            .addCase(getAllTasks.rejected, (state) => {
                return { ...state, loading: false };
            })
            .addCase(deleteTask.pending,(state)=>{
                return {...state,loading:true};
            })
            .addCase(deleteTask.fulfilled,(state)=>{
                return{...state,loading:false};
            })
            .addCase(deleteTask.rejected,(state)=>{
                return {...state,loading:false};
            })

    },
});




export default taskSlice.reducer;
