import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Screens/Login";
import Signup from "../Screens/Signup";
import AllTaskScreen from "../Screens/AllTaskScreen";
import ForgetPassword from "../Components/Forget Password/ForgetPassword";

const UnAuth = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        
        {/* <Route path="/dashboard" element={<AllTaskScreen />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default UnAuth;
