import React from "react";
import SideBar from "../Components/sidebar Component/SideBar";
import SignupForm from "../Components/SignupForm";
import AddTaskSVG from "../assets/image/svg/AddTaskSVG";
import AddMemberSVG from "../assets/image/svg/AddMemberSVG";
import AddRegisterSVG from "../assets/image/svg/AddRegisterSVG";

const Signup = () => {
  const sideBarData = [
    {
      icon: (
        <AddTaskSVG
          color={
            window.location.pathname.includes("/addTask")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      url: "/addTask",
    },

    {
      icon: (
        <AddRegisterSVG
          color={
            window.location.pathname.includes("/addRegister")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      url: "/signup",
    },

    {
      icon: (
        <AddMemberSVG
          color={
            window.location.pathname.includes("/addMember")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      url: "/addTask",
    },
  ];
  return (
    <div className="w-[100%] h-[100vh] p-0 flex">
      <SideBar data={sideBarData} />
      <div  style={{height:"100%",width:"100%"}}>
        <SignupForm />
      </div>
    </div>
  );
};

export default Signup;
