import { useState } from "react";
import CheckBox from "./CheckBox";
import { FiSearch } from "react-icons/fi";

const MultiSelectPopup = ({
  data,
  selectValue,
  dataType,
  setSelectValue,
  setFieldValue,
  name,
}: any) => {
  const [dataItem, setItem] = useState(data);
  const handleChange = (value: string) => {
    if (value == "") {
      setItem(data);
      return;
    }  
    const tempData = dataItem.filter((item: any) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setItem(tempData);
  };

  return (
    <div
      className={`absolute z-30 flex flex-col bg-white bottom-[5px] translate-y-[100%] right-[5px] px-2 rounded-xl pt-1 pb-4  border w-full border-[#D7D7D7] gap-1`}
      style={{ boxShadow: "0px 4px 8px 0px #0000004D" }}
    >
      <div className=" h-[40px] p-1">
        <div className="border w-full h-full border-[#DADADA] rounded-[6px] flex gap-1 items-center pl-1">
          <FiSearch />
          <input
            type="text"
            placeholder="Search"
            className="w-full outline-none placeholder:text-[#959595] placeholder:text-[12px]"
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      </div>
      {dataItem?.map((item: any, index: number) => {
        return (
          <CheckBox
            key={index}
            name={name}
            dataType={dataType}
            data={item}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            setFieldValue={setFieldValue}
          />
        );
      })}
    </div>
  );
};

export default MultiSelectPopup;
