import React from "react";
import { Droppable } from "react-beautiful-dnd";
import ArrowSVG from "../../assets/image/svg/ArrowSVG";
import CartTask from "./CartTask";
import { IoMdArrowBack } from "react-icons/io";
import { useAppSelector } from "../../Redux/Hooks";
import HighStatusSvg from "../../assets/image/svg/HighStatusSvg";

interface PropsType {
  inprogress: any;
  setTaskDetail?: any;
  setViewTask?: any;
  setView?: any;
  type?: any;
  setShowTask?: any;
  size: any;
  myTask: any;
}

const InprogressTask: React.FC<PropsType> = ({
  inprogress,
  setTaskDetail,
  setViewTask,
  setShowTask,
  type,
  size,
  myTask,
}) => {
  console.log(inprogress, "inprogress data");
  const { user } = useAppSelector((state) => state.user);

  const calcLength = () => {
    if (myTask === "true") {
      const tempArray = inprogress?.filter((item: any) =>
        item?.assign_to?.includes(user?._id)
      );
      return tempArray?.length;
    } else {
      return inprogress?.length;
    }
  };

  console.log(calcLength(), "length");
  return (
    <>
      <div className="mt-4 lg:hidden">
        <IoMdArrowBack size={25} onClick={() => setShowTask("")} />
      </div>
      <Droppable droppableId="InProgressList">
        {(provided) => (
          <div
            className="w-1/3 h-full bg-[#E2E8F0] p-2 rounded-[15px] md:w-full  md:mt-[20px] "
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="h-full flex  justify-center flex-wrap overflow-auto  ">
              <div className="w-full h-full flex flex-col gap-4  px-2 py-3   ">
                <div className="w-full flex px-2 ">
                  <span
                    className="text-[16px] font-semibold text-center px-5 py-1 bg-[#EADDD5] rounded-[7px] text-[#EE7E2E] "
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    {type === "labCase" ? "Recieved" : "IN PROGRESS"}(
                    {calcLength()})
                  </span>
                </div>
                <div className="flex flex-col  py-1 px-2">
                  <span className="font-medium text-[14px]">Task Started</span>
                  <span className="text-[#505050] text-[13px]">
                    The team started working on the tasks.
                  </span>
                </div>
                <div className="h-full flex flex-col gap-2 w-full overflow-auto  ">
                  {inprogress &&
                    inprogress?.map((item: any, index: number) => {
                      if (
                        item?.assign_to?.includes(user?._id) &&
                        myTask === "true"
                      ) {
                        return (
                          <CartTask
                            setViewTask={setViewTask}
                            setTaskDetail={setTaskDetail}
                            icon={<HighStatusSvg />}
                            item={item}
                            size={size}
                            index={index}
                          />
                        );
                      } else if (myTask === "false") {
                        return (
                          <CartTask
                            setViewTask={setViewTask}
                            setTaskDetail={setTaskDetail}
                            icon={<HighStatusSvg />}
                            item={item}
                            size={size}
                            index={index}
                          />
                        );
                      }
                    })}
                </div>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default InprogressTask;
