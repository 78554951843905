import { SVGProps } from "react"

const DragSVG = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M0 14v-2h2v2H0Zm4 0v-2h2v2H4Zm4 0v-2h2v2H8Zm-8-4V8h2v2H0Zm4 0V8h2v2H4Zm4 0V8h2v2H8ZM0 6V4h2v2H0Zm4 0V4h2v2H4Zm4 0V4h2v2H8ZM0 2V0h2v2H0Zm4 0V0h2v2H4Zm4 0V0h2v2H8Z"
    />
  </svg>
)
export default DragSVG