import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { useEffect, useState } from "react";
import { AddLeadTaskSchema } from "../../Validations/FormikValidation";
import InputField from "../Field Components/InputField";
import { addComment, createTask } from "../../Slices/TaskSlice";
import toast from "react-hot-toast";
import UploadFile from "../Field Components/UploadFile";
import Loader from "../../assets/image/svg/Loader";
import TaskFieldTable from "../Tables/TaskFieldTable";

const AddLeadTask = ({ refetch, setRefetch }: any) => {
  const [loader, setLoader] = useState(false);
  const userId: any = useAppSelector((state) => state?.user?.user);
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const adminUserArray: any[] = AdminUser ? [AdminUser] : [];

  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const initialValues = {
    title: "",
    description: "",
    comment: "",
    assignee: [],
    idDocuments: [
      {
        createdAt: "",
        document_ext: "",
        document_field: "idDocuments",
        document_link: "",
        document_name: "",
        document_type: "Attach Documents",
        task_id: "",
        updatedAt: "",
        __v: 0,
        _id: "",
      },
    ],
    owner: "",
    priority: "",
    deadline: "",
    taskType: "",
    status: "",
    creationDate: "",
    lead: "",
    dateOfContact: "",
    leadEmail: "",
    LeadPhone: "",
    text: "",
  };
  const handleTaskFunc = (value: any) => {
    const assignUser: any[] = value?.assignee?.map(
      (item: any, index: number) => {
        return item?._id;
      }
    );
    setLoader(true);

    const payload = {
      task_details: {
        title: value?.title,
        description: value?.description,
        owner: AdminUser?._id,
        priority: value?.priority.toLowerCase(),
        // status: value?.status,
        status: "todo",
        deadline: value?.deadline,
        assign_to: assignUser ? assignUser : [""],
        taskType: value?.taskType.toLowerCase(),
        lead: AdminUser?._id,
        lead_Phone: value?.LeadPhone,
        text_for_Lead: value?.text ? value?.text : "",
      },
      attached_files: value?.idDocuments,
    };
    dispatch(createTask(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {

          setRefetch(refetch + 1);
          nav("/leadTask");
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
        setLoader(false);
      })
      .catch((error) => {

        toast.error(error?.msg);
      });
  };
  const handleComment = (value: any) => {
    const payload = {
      comment: value.comment,
      task_id: "66ab7c7a41eda89b5a6a4a04",
      user_id: userId?._id,
    };
    dispatch(addComment(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {

          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        console.log(error);

      });
  };

  return (
    <>
      <div
        className="relative w-full h-full  bg-white rounded-[15px] scrollable overflow-auto  "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={AddLeadTaskSchema}
          onSubmit={(values, { resetForm }) => {
            handleComment(values);
            handleTaskFunc(values);

            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }: any) => (
            <Form
              onSubmit={handleSubmit}
              className="h-full flex flex-col justify-between"
            >
              <div className=" flex  overflow-auto px-10 flex-col md:px-6  ">
                <div className="w-full h-full">
                  <div className="w-full flex flex- justify-between py-4">
                    <span className="font-semibold">TM-1</span>
                    <div className="flex gap-10">
                      <RxCross2 size={20} onClick={() => nav("/leadTask")} />
                    </div>
                  </div>
                  <div className=" border-[#A0A0A0] rounded-[10px] ">
                    <InputField
                      className="w-full  rounded-lg border border-[#DADADA] italic"
                      fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                      placeholderStyle={{ top: "8px", left: "18px" }}
                      type="text"
                      name="title"
                      placeholder="Enter Title"
                      value={values?.title}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.title ? errors.title : undefined}
                    />
                  </div>
                  <div className="w-full flex py-4 md:flex-col">
                    <div className="w-3/5 md:w-full">
                      <div className="w-[85%] py-4 md:w-full">
                        <div className="font-medium pb-2">Description</div>
                        <div className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none">
                          <textarea
                            placeholder="Add a description..."
                            className="w-full min-h-[120px] outline-none"
                            name="description"
                            id="description"
                            value={values?.description}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-full py-2  flex flex-col gap-3">
                          <div className="w-full flex gap-2 items-center relative">
                            <span className=" font-semibold ">
                              {" "}
                              Attached Files
                            </span>

                            <div className=" flex relative font-medium text-[#0094FF] text-[14px] items-center gap-1">
                              Upload from here

                            </div>
                          </div>
                        </div>
                        <div className="w-full py-1">
                          <FieldArray
                            name="idDocuments"
                            render={(arrayHelpers) => {
                              return (
                                <>
                                  {values?.idDocuments?.map(
                                    (item: any, index: number) => (
                                      <div
                                        key={index}
                                        className=" flex flex-col"
                                      >
                                        <td className="relative ">
                                          <UploadFile
                                            className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                            fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                            placeholderStyle={{
                                              top: "10px",
                                              left: "16px",
                                            }}
                                            name={`idDocuments.${index}.document_name`}
                                            location={`idDocuments.${index}`}
                                            arrayName="idDocuments"
                                            placeholder={item?.document_type}
                                            value={item}
                                            fileFrom={"case"}
                                            setFieldValue={setFieldValue}
                                            onBlur={handleBlur}
                                            touched={touched}
                                            errors={
                                              errors?.idDocuments &&
                                              errors?.idDocuments[index]
                                                ?.document_name
                                            }
                                          />
                                          {index >
                                            initialValues?.idDocuments?.length -
                                            1 &&
                                            !item?.document_name && (
                                              <div className="absolute right-[-24px] top-3 text-[#FF5050] font-semibold">
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    arrayHelpers.remove(index);
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            )}
                                        </td>
                                      </div>
                                    )
                                  )}
                                  <div
                                    className="flex flex-col"
                                    onClick={() =>
                                      arrayHelpers.insert(
                                        values?.idDocuments?.length,
                                        {
                                          document_name: "",
                                          document_link: "",
                                          document_type: "Attach Documents",
                                          document_ext: "",
                                          document_field: "idDocuments",
                                        }
                                      )
                                    }
                                  >
                                    <td>
                                      <UploadFile
                                        className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                        fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                        placeholderStyle={{
                                          top: "10px",
                                          left: "16px",
                                        }}
                                        name="add_document"
                                        arrayName="idDocuments"
                                        data={values?.idDocuments}
                                        fileFrom={"case"}
                                        placeholder="+ Attach More Documents"
                                        setFieldValue={setFieldValue}
                                        onBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                      />
                                    </td>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div>

                      </div>
                    </div>
                    <div className="w-[40%] md:w-full h-full px-4 flex flex-col py-4 md:px-0">
                      <TaskFieldTable
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        adminUserArray={adminUserArray}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-full py-4 bg-white rounded-br-[20px] rounded-bl-[20px] px-4 "
                style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
              >
                <div className="w-full flex justify-end gap-4 py-2">
                  <button onClick={() => nav("/leadTask")}
                    type="button"
                    className=" w-[200px] border border-[#A0A0A0] py-3 rounded-[10px] font-semibold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                    style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                  >
                    Add Lead Task
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {loader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
          <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default AddLeadTask;
