import { SVGProps } from "react"

const CrossSVG = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M2.53 14.53 8 9.06l5.47 5.47L14 14l.53-.53L9.06 8l5.47-5.47L14 2l-.53-.53L8 6.94 2.53 1.47 2 2l-.53.53L6.94 8l-5.47 5.47L2 14l.53.53Z"
    />
  </svg>
)
export default CrossSVG
