import bgImage from "../../assets/image/bgImage.png";
import bgImage2 from "../../assets/image/bgImage2.png";
import { RiDoubleQuotesL } from "react-icons/ri";
const WelcomeLogin = () => {
  return (
    <div className="h-full w-[60%] flex justify-end items-center md:hidden">
      <div
        className="h-[65%] w-[70%] border-none rounded-tl-[30px] rounded-bl-[30px] flex flex-col justify-center items-start bg-primary px-10 z-10"
        style={{
          backgroundImage: `url(${bgImage}),url(${bgImage2})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "80% 0%,112% 112%",
          objectFit: "cover",
          backgroundSize: "250px 140px,300px 200px",
        }}
      >
        <h1 className="text-[32px]  font-semibold text-white ml-[45px]  mb-[20px]">
          Task Management
        </h1>
        <div className="flex ">
          <span className="my-[-20px] mx-[5px]">
            <RiDoubleQuotesL size={"40px"} color={"white"} />
          </span>
          <p className="text-[18px] text-white font-light  text-left">
            Effortlessly organize and prioritize your tasks with our intuitive
            task management software. Enhance productivity and stay on top of
            your deadlines with a streamlined interface designed for efficiency.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeLogin;
