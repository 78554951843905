import logo from "../../assets/image/Logo.png";
import { IoPersonSharp } from "react-icons/io5";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { loginAccount } from "../../Slices/UserSlice";
import { LoginValidationSchema } from "../../Validations/FormikValidation";
import InputField from "../Field Components/InputField";
import Loader from "../../assets/image/svg/Loader";
import { useState } from "react";
import ForgetPassword from "../Forget Password/ForgetPassword";
import VerifyPassword from "../Forget Password/VerifyPassword";
import UpdatePassword from "../Update Component/UpdatePassword";
import ChangePassword from "../Forget Password/ChangePassword";

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const [forget, setForget] = useState(false);
  const [update,setUpdate]=useState(false);

  const nav = useNavigate();
  const HandleLoginFunc = (email: string, password: string) => {
    const payload = {
      email: email,
      password: password,
    };
    dispatch(loginAccount(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
         
          nav("/dashboard");
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        
        toast.error(error?.msg);
      });
    // dispatch(userLogin());
    // nav("/dashboard");
  };
  const loading = useAppSelector((state) => state.user.loading);
  const [verify, setVerify] = useState(false);
  const [email,setEmail]=useState("")

  return (
    <div className="h-full  w-[40%] flex items-center md:w-full md:px-4 ">
      {/* <div className="h-[90%]  pr-2 w-[75%] scrollable overflow-auto  flex items-center py-2 bg-white "
      
      > */}
      <div
        className="h-[90%] w-[75%] md:w-full  pb-4  rounded-[25px] "
        style={{ boxShadow: "-5px 6px 15px 0px #729CB2" }}
      >
        <div className="w-full h-auto flex justify-center  bg-[#E9EEF2] py-[20px] rounded-tr-[25px] rounded-tl-[25px] ">
          <img
            className=" w-[200px] h-[180px]  overflow-hidden"
            src={logo}
            alt=""
          />
        </div>
        <div
          className="grid scrollable overflow-auto  px-6 py-[6%]  "
          style={{ height: "calc(100% - 220px)", alignContent: "center" }}
        >
          <div className="w-full flex justify-center">
            <div
              className="w-[50px] border bg-primary border-none py-3 rounded-[15px] flex justify-center items-center "
              style={{ boxShadow: "0px 5px 18px 0px #B2CBF8" }}
            >
              <IoPersonSharp color="white" size={20} />
            </div>
          </div>

          <span className="font-semibold text-center text-primary text-[22px]">
            User Login
          </span>

          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginValidationSchema}
            onSubmit={(values) => {
              HandleLoginFunc(values.email, values.password);
           
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex  flex-col justify-center gap-[12px]  mt-[1%]">
                  <span className="text-[14px] font-medium ">
                    Personal Info
                  </span>
                  <InputField
                    className="w-full bg-white rounded-lg border border-[#DADADA]"
                    fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                    placeholderStyle={{ top: "8px", left: "24px" }}
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    fieldDisable={false}
                    touched={touched}
                    handleChange={handleChange}
                    errors={errors.email ? errors.email : undefined}
                  />
                  <InputField
                    className="w-full bg-white rounded-lg border border-[#DADADA]"
                    fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                    placeholderStyle={{ top: "8px", left: "24px" }}
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    touched={touched}
                    fieldDisable={false}
                    handleChange={handleChange}
                    errors={errors.password ? errors.password : undefined}
                  />

                  <div className="w-full flex justify-end items-center ">
                    <span
                      onClick={() => {
                        setForget(true);
                      }}
                      className="text-[#D65252] font-medium text-[14px] cursor-pointer"
                    >
                      Forgot Password?
                    </span>
                  </div>

                  <button className="border h-[40px] rounded-lg flex justify-center items-center text-[20px] font-semibold text-white bg-primary mt-[20px]">
                    {loading ? <Loader /> : "Login"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {forget && <ForgetPassword setVerify={setVerify} setForget={setForget} setEmail={setEmail} />}
      {verify && <VerifyPassword setVerify={setVerify}  setUpdate={setUpdate} email={email}/>}
      {update && <ChangePassword  setPassword={setUpdate} email={email}/>}
      {}
    </div>
    // </div>
  );
};

export default LoginForm;
