import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { updateTask } from "../../Slices/TaskSlice";
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import { AddTaskSchema } from "../../Validations/FormikValidation";
import InputField from "../Field Components/InputField";
import { useState } from "react";
import UploadFile from "../Field Components/UploadFile";
import TaskTable from "../Tables/TaskTable";
import TaskFieldTable from "../Tables/TaskFieldTable";
import CommentSection from "../CommentSection";
import Loader from "../../assets/image/svg/Loader";

const EditUserTask = ({ refetch, setRefetch }: any) => {
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [comments, setComments] = useState([])
  const [loader, setLoader] = useState(false);
  const data = location.state || [];
  console.log(data, "data ------------ ", data)
  const [comment, setComment] = useState([]);
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const adminUserArray: any[] = AdminUser ? [AdminUser] : [];
  const { task, destinationId, destinationIndex } = location.state;
  // const commentData: any[] = data.task.commentsData;
  console.log(data.task, destinationId, "destination id for task stauts")
  const initialValues = {
    title: data[0]?.title ? data[0]?.title : "",
    description: data[0]?.description ? data[0]?.description : "",
    comment: data[0]?.commentsData ? data[0]?.commentsData : [],
    assignee: data[0]?.assignUsersData ? data[0]?.assignUsersData : [],
    idDocuments: data[0]?.DocumentsData
      ? data[0]?.DocumentsData
      : [
        {
          createdAt: "",
          document_ext: "",
          document_field: "idDocuments",
          document_link: "",
          document_name: "",
          document_type: "Attach Documents",
          task_id: "",
          updatedAt: "",
          __v: 0,
          _id: "",
        },
      ],
    owner: data[0]?.ownerDetails ? data[0]?.ownerDetails : [],
    priority: data[0]?.priority ? data[0]?.priority : "",
    deadline: data[0]?.deadline ? data[0]?.deadline : "",
    taskType: data[0]?.taskType ? data[0]?.taskType : "",
    status: data[0]?.status ? data[0]?.status : "",
    creationDate: data[0]?.createdAt ? data[0]?.createdAt : "",
    lead: data[0]?.leadData ? data[0]?.leadData : "",
    // dateOfContact: data[0]?.createdAt
    //   ? data[0]?.createdAt
    //   : "",
    // leadEmail: data[0]?.leadData[0]?.email ? data[0]?.leadData[0]?.email : "",
    LeadPhone: data[0]?.lead_Phone ? data[0]?.lead_Phone : "",
    text: data[0]?.text_for_Lead ? data[0]?.text_for_Lead : "",
  };
  console.log(adminUserArray, "admin user")
  const handleTaskFunc = (value: any) => {
    const assignUser: any[] = value?.assignee?.map(
      (item: any, index: number) => {
        return item?._id;
      }
    );

    setLoader(true);
    let payload;
    if (data[0]?.lead === null) {
      payload = {
        task_id: data[0]?._id,

        task_details: {
          title: value?.title,
          description: value?.description,
          owner: value?._id,
          priority: value?.priority.toLowerCase(),
          status: value?.status,
          deadline: value?.deadline,
          assign_to: assignUser ? assignUser : [""],
          taskType: value?.taskType.toLowerCase(),
        },
        attached_files: value?.idDocuments,
      };
    } else {
      payload = {
        task_id: data[0]?._id,
        task_details: {
          title: value?.title,
          description: value?.description,
          owner: value?._id,
          priority: value?.priority.toLowerCase(),
          status: value?.status,
          deadline: value?.deadline,
          assign_to: assignUser ? assignUser : [""],
          taskType: value?.taskType.toLowerCase(),
          lead: AdminUser?._id ? AdminUser?._id : "",
          lead_Phone: value?.LeadPhone ? value?.LeadPhone : "",
          text_for_Lead: value?.text ? value?.text : "",
        },
        attached_files: value?.idDocuments,
      };
    }

    dispatch(updateTask(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setRefetch(refetch + 1);
          if (data[0]?.lead === null) {
            nav("/dashboard");
          } else {
            nav("/leadTask");
          }

          toast.success(res?.msg, {
            position: "top-center",
          });
          setLoader(false);
        }
      })
      .catch((error) => {

        toast.error(error?.msg);
        setLoader(false);
      });
  };

  const handleClose = () => {
    setRefetch(refetch + 1);
    if (data[0]?.lead === null) {
      nav("/dashboard");
    } else {
      nav("/leadTask");
    }

  };

  return (
    <div
      className="relative w-full bg-white h-full rounded-[15px] scrollable overflow-auto  "
      style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={AddTaskSchema}
        onSubmit={(values, { resetForm }) => {
          // handleComment(values);
          handleTaskFunc(values);

          // resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }: any) => {
          return (
            <Form
              onSubmit={handleSubmit}
              className="h-full flex flex-col justify-between"
            >
              <div className="flex overflow-auto px-10 flex-col  md:px-6 ">
                <div className="w-full h-full">
                  <div className="w-full flex justify-between py-4">
                    <span className="font-semibold">TM-1</span>
                    <div className="flex gap-10">
                      <RxCross2 size={20} onClick={handleClose} />
                    </div>
                  </div>
                  <div className="  border-[#A0A0A0] rounded-[10px] ">
                    <InputField
                      className="w-full  rounded-lg border border-[#DADADA] italic"
                      fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                      placeholderStyle={{ top: "8px", left: "18px" }}
                      type="text"
                      name="title"
                      placeholder="Enter Title"
                      value={values?.title}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.title ? errors.title : ""}
                    />
                  </div>
                  <div className="w-full flex py-4 md:flex-col">
                    <div className="w-3/5 md:w-full">
                      <div className="w-[85%] py-4 md:w-full">
                        <div className="font-medium pb-2">Description</div>
                        <div className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none">
                          <textarea
                            placeholder="Add a description..."
                            className="w-full min-h-[120px] outline-none"
                            name="description"
                            id="description"
                            value={values?.description}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-full py-2  flex flex-col gap-3">
                          <div className="w-full flex gap-2 items-center relative">
                            <span className=" font-semibold ">
                              {" "}
                              Attached Files
                            </span>

                            <div className=" flex relative font-medium text-[#0094FF] text-[14px] items-center gap-1">
                              Upload from here
                            </div>
                          </div>
                        </div>
                        <div className="w-full py-1">
                          <FieldArray
                            name="idDocuments"
                            render={(arrayHelpers) => {
                              return (
                                <>
                                  {values?.idDocuments?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          className=" flex flex-col"
                                        >
                                          <td className="relative ">
                                            <UploadFile
                                              className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                              fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                              placeholderStyle={{
                                                top: "10px",
                                                left: "16px",
                                              }}
                                              name={`idDocuments.${index}.document_name`}
                                              location={`idDocuments.${index}`}
                                              arrayName="idDocuments"
                                              placeholder={item?.document_type}
                                              value={item}
                                              fileFrom={"case"}
                                              setFieldValue={setFieldValue}
                                              onBlur={handleBlur}
                                              touched={touched}
                                              errors={
                                                errors?.idDocuments &&
                                                errors?.idDocuments[index]
                                                  ?.document_name
                                              }
                                            />
                                            {index >
                                              initialValues?.idDocuments
                                                ?.length -
                                              1 &&
                                              !item?.document_name && (
                                                <div className="absolute right-[-24px] top-3 text-[#FF5050] font-semibold">
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              )}
                                          </td>
                                        </div>
                                      );
                                    }
                                  )}
                                  <div
                                    className="flex flex-col"
                                    onClick={() =>
                                      arrayHelpers.insert(
                                        values?.idDocuments?.length,
                                        {
                                          document_name: "",
                                          document_link: "",
                                          document_type: "Attach Documents",
                                          document_ext: "",
                                          document_field: "idDocuments",
                                        }
                                      )
                                    }
                                  >
                                    <td>
                                      <UploadFile
                                        className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                        fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                        placeholderStyle={{
                                          top: "10px",
                                          left: "16px",
                                        }}
                                        name="add_document"
                                        arrayName="idDocuments"
                                        data={values?.idDocuments}
                                        fileFrom={"case"}
                                        placeholder="+ Attach More Documents"
                                        setFieldValue={setFieldValue}
                                        onBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                      />
                                    </td>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div>

                        <CommentSection
                          length={0}
                          values={values.comment}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          data={data}
                          comment={comment}
                          setComment={setComment}
                          refetch={refetch}
                          setRefetch={setRefetch}
                        />
                      </div>
                    </div>
                    <div className="w-[40%] md:w-full md:px-0 h-full px-4 flex flex-col py-4">
                      {data[0]?.lead === null ? (
                        <TaskTable
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          AdminUser={adminUserArray}
                        />
                      ) : (
                        <TaskFieldTable
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          adminUserArray={adminUserArray}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-full py-4 bg-white rounded-br-[20px] rounded-bl-[20px] px-4 "
                style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
              >
                <div className="w-full flex justify-end gap-4 py-2">
                  <button
                    onClick={handleClose}
                    type="button"
                    className=" w-[200px] border border-[#A0A0A0] py-3 rounded-[10px] font-semibold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                    style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                  >
                    Save Task
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {loader &&
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
          <Loader />
        </div>

      }
    </div>
  );
};

export default EditUserTask;
