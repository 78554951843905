import React, { useState } from "react";
import Loader from "../../assets/image/svg/Loader";
import UserDetails from "../User Component/UserDetails";
import { GoDotFill } from "react-icons/go";

interface propsType {
  setUser: (a: any) => void;
  user: boolean;
  userDetails: any;
}
interface type {
  createdAt: string;
  email: string;
  name: string;
  password: string;
  updatedAt: string;
  userStatus: string;
  userType: string;
  __v: number;
  _id: string;
  username:string
}
const Table: React.FC<propsType> = ({ setUser, user, userDetails }) => {
  const [data, setData] = useState<any>([]);

  return (
    <div
      className="w-[100%] h-full bg-white rounded-[15px]  px-2 pb-1"
      style={{ boxShadow: "0px 4px 5px 0px #00000033" }}
    >
      <div className="w-full h-full  overflow-auto">
        {userDetails.length ? (
          <table className=" w-full text-center mt-2 rounded-tl-[15px] border-collapse ">
            <thead
              className="w-full text-[16px] font-medium h-8 bg-[#EDF1F6] "
              style={{ borderRadius: "15px, 15px, 0px, 0px" }}
            >
              <tr className="w-full">
                <th className="font-semibold  rounded-tl-[15px]">S.No.</th>
                <th className="font-semibold">Username</th>
                <th className="font-semibold">Email</th>
                <th className="font-semibold">Status</th>
                <th className="font-semibold  rounded-tr-[15px] text-nowrap">
                  {" "}
                  User Details{" "}
                </th>
              </tr>
            </thead>
            {userDetails &&
              userDetails.map((item: type, index: number) => {
                
                return (
                  <tbody key={index} className="text-[14px] overflow-auto">
                    <tr className="border-b-2  h-14 border-[#E5E5E5]">
                      <th className="font-normal">{index + 1}</th>
                      <th className="font-normal">{item?.name  ? item?.name : item?.username }</th>
                      <th className="font-normal">{item?.email}</th>
                      <th className="font-normal  ">
                        <div className="flex items-center justify-center ">
                          {/* <span
                            className={`slide-button ${
                              item?.userStatus === "active" ? "on" : "off"
                            } `}
                            style={{
                              backgroundColor:
                                item?.userStatus === "active"
                                  ? "#6EC350"
                                  : "#CCCCCC",
                              display: "flex",
                              cursor: "pointer",
                            }}
                          >
                            <span className="slider " />
                          </span> */}
                          <div className="px-1">
                            {item?.userStatus === "active" ? (
                              <div className="flex items-center gap-1">
                                <GoDotFill size={20} color="#6EC350" /> 
                                <span>Active</span>
                              </div>
                               
                            ) : (
                              <div className="flex items-center ml-1 ">
                                <GoDotFill size={20} color="#CCCCCC" /> 
                                <span>Inactive</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </th>
                      <th className="font-medium text-[#0094FF]">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setUser(true);
                            setData(item);
                          }}
                        >
                          View All {">"}
                        </div>
                      </th>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        ) : (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
            <Loader />
          </div>
        )}
        {user && <UserDetails setUser={setUser} data={data} />}
      </div>
    </div>
  );
};

export default Table;
