import React, { useCallback } from "react";
import { IoSearch } from "react-icons/io5";
import debounce from "lodash/debounce";

const SearchBar = ({ setSearch }: any) => {

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 500),
    [setSearch] 
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  return (
    <div
      className="w-[40%] md:w-full h-[40px] md:h-[40px] flex items-center bg-[white] rounded-[9px] border-none"
      style={{ boxShadow: "0px 1.8px 3.59px -0.9px #00000040" }}
    >
      <span className="text-center ml-2">
        <IoSearch color={"#3D5765"} size={20} />
      </span>
      <input
        type="text"
        className="w-full h-full outline-none px-2 placeholder:text-[#676B8A] rounded-br-[9px] rounded-tr-[9px] md:placeholder:text-[18px]"
        onChange={handleSearch}
        placeholder="Search"
      />
    </div>
  );
};

export default SearchBar;




// import React, { useCallback } from "react";
// import { IoSearch } from "react-icons/io5";


// const SearchBar = ({ setSearch }: any) => {
//   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearch(event.target.value);
//   };
 
//   return (
//     <div
//       className="w-[40%] md:w-full  h-[40px] md:h-[40px]  flex  items-center  bg-[white] rounded-[9px]  border-none"
//       style={{ boxShadow: "0px 1.8px 3.59px -0.9px #00000040" }}
//     >
//       <span className="text-center ml-2 ">
//         <IoSearch color={"#3D5765"} size={20} />
//       </span>
//       <input
//         type="text"
//         className=" w-full h-full outline-none px-2 placeholder:text-[#676B8A] rounded-br-[9px]
//  rounded-tr-[9px] md:placeholder:text-[18px]
//  "
//         onChange={handleSearch}
//         placeholder="Search"
//       />

   
//     </div>
//   );
// };

// export default SearchBar;
