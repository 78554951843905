import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../Redux/Hooks";
import DateField from "../Field Components/DateField";
import SelectField from "../Field Components/SelectField";
import MultiSelectField from "../Modal Component/MultiSelectField";
import TableInputField from "./TableInputField";

interface propsType {
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  adminUserArray: any;
}
const TaskFieldTable: React.FC<propsType> = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  adminUserArray,
}) => {
  const userData: any = useAppSelector((state) => state?.user?.userData);

  const priority = ["High", "Medium", "Low"];
  const TaskType = ["lead", "maintenance", "sample"];
  const status = ["todo", "inprogress", "completed"];
  const { pathname } = useLocation();
  return (
    <>
      <span className=" text-[14px] font-medium  py-2 ">Task Details</span>

      <div className="w-full flex border h-1/2 p-4  overflow-auto  border-[#A0A0A0] rounded-[10px] ">
        <table className="w-full  ">
          <tbody className="">
            <tr className="border-b border-[#DADADA] ">
              <td className="py-4 text-[#7A7A7A]">Lead:</td>
              <td className="text-[14px] font-medium">
                <MultiSelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full min-h-[40px]  pr-4 py-2"
                  name="lead"
                  value={values.lead}
                  dataType="object"
                  options={adminUserArray}
                  errors={errors?.lead}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr>
            <tr className="border-b border-[#DADADA] ">
              <td className=" py-4 text-[#7A7A7A]">Assignee:</td>
              <td className="text-[14px] font-medium ">
                <MultiSelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full min-h-[40px]  pr-4 py-2"
                  name="assignee"
                  value={values.assignee}
                  dataType="object"
                  options={userData}
                  errors={errors?.assignee}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr>
            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Owner:</td>
              <td className="text-[14px] font-medium">
              <MultiSelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full min-h-[40px] pl-2 pr-4 py-2"
                  name="owner"
                  value={values.owner}
                  dataType="object"
                  options={adminUserArray}
                  errors={errors?.owner}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr> */}
            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Priority:</td>
              <td className="text-[14px] font-medium w-[70%]">
                <SelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-4 pr-4"
                  name="priority"
                  options={priority}
                  value={values.priority}
                  dataType="string"
                  errors={errors?.priority}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Deadline:</td>
              <td className="text-[14px] font-medium ">
                <DateField
                  className="w-full"
                  fieldClass="w-full h-[40px] pl-4 outline-none uppercase pr-3"
                  placeholderStyle={{ top: "8px", left: "16px" }}
                  type="date"
                  name="deadline"
                  placeholder=""
                  value={values?.deadline}
                  touched={touched}
                  errors={errors?.deadline}
                  handleChange={handleChange}
                />
              </td>
            </tr>
            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Task Type:</td>
              <td className="text-[14px] font-medium">
                <SelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-5 pr-4"
                  name="taskType"
                  fieldDisable={false}
                  options={TaskType}
                  value={values.taskType}
                  dataType="string"
                  errors={errors?.taskType}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr> */}
            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Status:</td>
              <td className="pl-4 text-[14px] font-medium">
                {pathname !== "/edit" ? (
                  <span className="  text-[12px] text-center px-4 py-[3px] bg-[#0496FF33] rounded-[7px] text-[#0496FF] font-semibold">
                    TO DO
                  </span>
                ) : (
                  <SelectField
                    className="flex gap-5 items-center w-full"
                    fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-4 pr-4"
                    name="status"
                    fieldDisable={false}
                    options={status}
                    value={values.status}
                    dataType="string"
                    errors={errors?.status}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                )}
              </td>
            </tr>
            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Creation Date:</td>
              <td className="text-[14px] font-medium ">
                <DateField
                  className="w-full"
                  fieldClass="w-full h-[40px] pl-5 outline-none uppercase pr-3"
                  placeholderStyle={{ top: "8px", left: "16px" }}
                  type="date"
                  name="creationDate"
                  placeholder=""
                  value={values?.creationDate}
                  touched={touched}
                  errors={errors?.creationDate}
                  handleChange={handleChange}
                />
              </td>
            </tr> */}

            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Date Contacted:</td>
              <td className="text-[14px] font-medium">
                {" "}
                <DateField
                  className="w-full"
                  fieldClass="w-full h-[40px] pl-5 outline-none uppercase pr-3"
                  placeholderStyle={{ top: "8px", left: "16px" }}
                  type="date"
                  name="dateOfContact"
                  placeholder=""
                  value={values?.dateOfContact}
                  touched={touched}
                  errors={errors?.dateOfContact}
                  handleChange={handleChange}
                />
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Lead Email:</td>
              <td className="text-[14px] font-medium">
                <TableInputField
                  className="w-full"
                  fieldClass="w-full h-[40px] pl-5 outline-none  pr-3"
                  placeholderStyle={{ top: "8px", left: "16px" }}
                  type="email"
                  name="leadEmail"
                  placeholder=""
                  value={values?.leadEmail}
                  touched={touched}
                  errors={errors?.leadEmail}
                  handleChange={handleChange}
                />
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Lead Phone:</td>
              <td className="text-[14px] font-medium">
                <TableInputField
                  className="w-full"
                  fieldClass="w-full h-[40px] pl-5 outline-none  pr-3"
                  placeholderStyle={{ top: "8px", left: "16px" }}
                  type="text"
                  name="LeadPhone"
                  placeholder=""
                  value={values?.LeadPhone}
                  touched={touched}
                  errors={errors?.LeadPhone}
                  handleChange={handleChange}
                />
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Text:</td>
              <td className="text-[14px] font-normal">
                <div className=" border border-[#A0A0A0]  rounded-[10px] p-2 my-4 flex justify-center h-20">
                  <textarea
                    name="text"
                    placeholder="Add a Text"
                    className="w-full outline-none placeholder:font-normal"
                    onChange={handleChange}
                    value={values?.text}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TaskFieldTable;
