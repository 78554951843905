import NewHeader from "../Components/Header Component/NewHeader"
import AddTask from "../Components/TaskComponents/AddTask"

const AddTaskScreen = ({refetch,setRefetch}:any) => {

  return (
   <div className="h-full w-full flex">
  
   <div className="flex h-full flex-col gap-2 py-4 px-10 md:px-6" style={{width:"100%"}}>
     <NewHeader 
      heading="Add Task"
     />
     <AddTask 
       refetch={refetch}
       setRefetch={setRefetch}
     />
   </div>
 </div>
  )
}

export default AddTaskScreen