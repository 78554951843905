import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import DragSVG from "../../assets/image/svg/DragSVG";
import Billing from "../../assets/icons/billing.png";
import CalenderSVG from "../../assets/image/svg/CalenderSVG";
import HighStatusSvg from "../../assets/image/svg/HighStatusSvg";

const CartTask = ({ setViewTask }: any) => {
  const handleChange = () => {
    setViewTask(true);
  };
  return (
    <>
      <div
        className={`h-auto w-auto mx-2 p-4 m-1 border-none bg-white rounded-[14px] cursor-pointer `}
        style={{ boxShadow: "0px 1.8px 8.98px 0px #0000000D" }}
        onClick={handleChange}
      >
        <div className="w-full flex justify-between  items-center ">
          <div className="flex items-center gap-3 w-[55%]">
            <span className="w-[14px] h-[14px]">
              <HighStatusSvg />
            </span>
            <p className="font-semibold w-[70%] text-ellipsis text-nowrap overflow-auto">
              Quality Dispute
            </p>
          </div>

          <div className="flex items-center gap-2">
            <button className="  text-[12px] text-center px-5 py-1 bg-[#0496FF33] rounded-[7px] text-[#0496FF] font-medium">
              New
            </button>

            <span className="">
              <DragSVG />
            </span>
            {/* <BsThreeDotsVertical size={20} /> */}
          </div>
        </div>
        <div className="flex w-full justify-between  items-center my-2 ">
          <div className="flex items-center gap-2  text-nowrap overflow-auto text-ellipsis">
            <img src={Billing} width={"15px"} />
            {/* <div className="w-[20px] h-[20px] bg-[#3E5F71] rounded-full flex justify-center items-center "> */}
            <span className="text-[10px] font-normal text-black">
              Lead Patient
            </span>
            {/* </div> */}
            {/* <ArrowSVG /> */}
            {/* {item?.assignUsersData?.map((item: any, index: number) => {
              const fullName = item?.name;
              const nameParts = fullName.split(" ");
              const firstInitial = nameParts[0]?.charAt(0);
              const lastInitial = nameParts[0]?.charAt(1); */}
            {/* return ( */}
            <div className="w-[20px] h-[20px] bg-[#3E5F71] rounded-full flex justify-center items-center ">
              <span className="text-[10px] font-normal text-white">ab</span>
            </div>
            {/* ); */}
            {/* })} */}
          </div>
          <div className="flex items-center gap-2 ">
            <CalenderSVG />

            <span className="text-[11px] text-[#8591A2]">
              {/* {moment(item?.deadline).format("ll")} */}
              Nov 16, 2024
            </span>
          </div>
        </div>

        <div className="border w-full h-[120px] md:h-[45px] overflow-auto  outline-none p-2 bg-[#D9D9D926] rounded-[5px]  border-[#8591A22E]">
          <p>this is new lead components</p>
        </div>
      </div>
    </>
  );
};

export default CartTask;
