
import NewHeader from "../Components/Header Component/NewHeader";
import EditUserTask from "../Components/User Component/EditUserTask";

const AllEditScreen = ({refetch,setRefetch}:any) => {
  return (
    <div className="h-full w-full flex">
      <div className="w-full h-full flex flex-col gap-2 py-4 px-10 md:px-4">
        <NewHeader heading="Edit Task" />
        <EditUserTask 
         refetch={refetch}
         setRefetch={setRefetch}
        />
      </div>
    </div>
  );
};

export default AllEditScreen;
