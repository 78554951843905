import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useLocation } from "react-router-dom";

interface propType {
  placeholder: string;
  className: string;
  fieldClass: string;
  placeholderStyle: object;
  type: string;
  name: string;
  fieldDisable?: any;
  value: string | number;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: any;
  touched?: any;
  handleBlur?: any;
}

const InputField: React.FC<propType> = ({
  className,
  placeholder,
  placeholderStyle,
  type,
  name,
  fieldDisable = false,
  fieldClass,
  value,
  handleChange,
  errors,
  touched,
  handleBlur
}) => {
  const { pathname } = useLocation();
  const [protect, setProtect] = useState(true);

  return (
    <div className={`flex ${pathname === "/registerUser" && "my-2"} `}>
      <div
        className={
          className +
          ` flex relative mb-2 ` +
          `${errors && "border-red-600 border-solid"}`
        }
        style={{
          boxShadow:
            name === "title" || name === "labName" || name==="sendDate" ? "" : "0px 5px 15px 0px #D4DEEA",
        }}
      >
        <input
          className={
            fieldClass +
            " bg-transparent relative z-[1] " +
            `${fieldDisable && "text-[#909090]"}`
          }
          type={type === "password" ? (protect ? type : "text") : type}
          placeholder={placeholder}
          name={name}
          value={value}
          id={name}
          onChange={handleChange}
          disabled={fieldDisable}
          onBlur={handleBlur}
          autoComplete="off"
        />
        {type === "password" && (
          <div className=" flex items-center pr-2">
            {protect ? (
              <AiFillEyeInvisible onClick={() =>setProtect(false)} />
            ) : (
              <AiFillEye onClick={() =>setProtect(true)} />
            )}
          </div>
        )}

        <div className="text-[#FF5050]  text-[12px] absolute top-10 capitalize font-medium pt-1">
          {errors && touched && typeof errors === "string" && errors}
        </div>
      </div>
    </div>
  );
};

export default InputField;
