import { TbBellFilled } from "react-icons/tb";
import { BsPersonCircle } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import mobileLogo from "../assets/image/MobileLogo.png";
import InputField from "./Field Components/InputField";
import { useState } from "react";
import SucessPopups from "../Popups/SucessPopups";
import { Formik } from "formik";
import { SignupValidationSchema } from "../Validations/FormikValidation";
import { SignUpAccount } from "../Slices/UserSlice";
import { useAppDispatch } from "../Redux/Hooks";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const SignupForm = () => {
  const [popup, setPopup] = useState(false);
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const HandleSignUpFunc = (values: any) => {
    const payload = {
      email: values.email,
      password: values.password,
      name: values.userName,
      userStatus: "active",
    };
    dispatch(SignUpAccount(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        toast.error(error?.msg);
      });
  };
  return (
    <>
      <div className=" bg-[#EDF1F6] h-full w-full py-4 px-10  ">
        <div className="flex justify-between items-center mb-4 ">
          <span className="text-[20px] font-medium">Register User</span>
          <div className="flex gap-8">
            <TbBellFilled color="#3D5765" size={30} />
            <BsPersonCircle color="#3D5765" size={30} />
          </div>
        </div>
        <div className="flex p-4 bg-white h-[93%] w-[100%] rounded-[15px] ">
          <div className="w-[50%] flex justify-center items-center">
            <img className="w-[350px] h-[450px]" src={mobileLogo} alt="logo" />
          </div>
          <div className="w-[50%] h-full flex flex-col items-center overflow-auto ">
            <div className="flex justify-between w-[65%] items-center ">
              <span className="font-medium">User Details</span>
              <span>
                <RxCross2 />
              </span>
            </div>
            <div
              className="h-[100%] px-10 py-6 m-4 rounded-[20px]"
              style={{ boxShadow: "0px 4px 5px 2px #00000033" }}
            >
              <Formik
                initialValues={{
                  userName: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={SignupValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  HandleSignUpFunc(values);
                  
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    className=" flex flex-col gap-2"
                    onSubmit={handleSubmit}
                  >
                    <label className="font-medium text-[14px]">
                      UserName <span className="text-[#FF2F2F]">*</span>
                    </label>
                    <InputField
                      className="w-full bg-white rounded-lg border border-[#DADADA]"
                      fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                      placeholderStyle={{ top: "8px", left: "18px" }}
                      type="text"
                      name="userName"
                      placeholder="Enter Username"
                      value={values.userName}
                      fieldDisable={false}
                      handleChange={handleChange}
                      errors={
                        touched.userName || errors.userName
                          ? errors.userName
                          : undefined
                      }
                    />
                    <label className="font-medium text-[14px]">
                      Email <span className="text-[#FF2F2F]">*</span>
                    </label>
                    <InputField
                      className="w-full bg-white rounded-lg border border-[#DADADA]"
                      fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                      placeholderStyle={{ top: "8px", left: "18px" }}
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      value={values.email}
                      fieldDisable={false}
                      handleChange={handleChange}
                      errors={
                        touched.email || errors.email ? errors.email : undefined
                      }
                    />
                    <label className="font-medium text-[14px]">
                      Password <span className="text-[#FF2F2F]">*</span>
                    </label>
                    <InputField
                      className="w-full bg-white rounded-lg border border-[#DADADA]"
                      fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                      placeholderStyle={{ top: "8px", left: "18px" }}
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      value={values.password}
                      fieldDisable={false}
                      handleChange={handleChange}
                      errors={
                        touched.password || errors.password
                          ? errors.password
                          : undefined
                      }
                    />
                    <label className="font-medium text-[14px]">
                      Confirm Password <span className="text-[#FF2F2F]">*</span>
                    </label>
                    <InputField
                      className="w-full bg-white rounded-lg border border-[#DADADA]"
                      fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                      placeholderStyle={{ top: "8px", left: "18px" }}
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={values.confirmPassword}
                      fieldDisable={false}
                      handleChange={handleChange}
                      errors={
                        touched.confirmPassword || errors.confirmPassword
                          ? errors.confirmPassword
                          : undefined
                      }
                    />

                    <div className="flex flex-col mt-2">
                      <span className="font-medium text-[14px]">
                        User Status{" "}
                        <span className="text-[#CCCCCC]">
                          (Active/Inactive){" "}
                        </span>
                        <span className="text-[#FF2F2F]">*</span>{" "}
                      </span>
                      <label className="relative inline-flex items-center cursor-pointer mt-3">
                        <input
                          type="checkbox"
                          value=""
                          className="sr-only peer"
                        />
                        <div className="group peer ring-0 bg-[#CCCCCC] rounded-full outline-none duration-300 after:duration-300 w-[70px] h-[25px]   shadow-md peer-checked:bg-[#6EC350] peer-focus:outline-none    after:rounded-full after:absolute after:bg-white after:outline-none after:h-[20px] after:w-[20px] after:top-0.5  after:flex after:justify-center after:items-center  peer-checked:after:translate-x-12 "></div>
                        <span className="text-[12px] ml-2">Active</span>
                      </label>
                    </div>

                    <div className="flex justify-center gap-4 mt-3">
                      <button
                        type="button"
                        className="px-10 py-2 text-[14px] border border-[#A0A0A0] rounded-[10px] font-semibold"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        // onClick={() => setPopup(true)}
                        className="px-8 rounded-[10px] text-[14px] bg-[#3D5765] font-semibold text-white"
                      >
                        Register User
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {popup && <SucessPopups setPopup={setPopup} />}
    </>
  );
};

export default SignupForm;
