import { SVGProps } from "react"

const HighStatusSvg = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#D65252"
        fillRule="evenodd"
        d="M9.716.67a1.012 1.012 0 0 0-1.432 0L.67 8.284a1.012 1.012 0 0 0 0 1.432l7.614 7.613a1.012 1.012 0 0 0 1.432 0l7.613-7.613a1.012 1.012 0 0 0 0-1.432L9.716.67Zm-.319 4.183a.562.562 0 0 0-.803.007l-3 3.125a.563.563 0 0 0 .812.78l2.031-2.117V12a.563.563 0 0 0 1.125 0V6.608l2.04 2.04a.563.563 0 1 0 .795-.795l-3-3Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default HighStatusSvg
