import * as Yup from "yup";

export const LoginValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

export const SignupValidationSchema = Yup.object({
  userName: Yup.string()
    .min(2, "User Name is too short")
    .max(50, "User Name is too long")
    .required("User Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

export const updatePasswordSchema = Yup.object({
  newPassword: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm Password is required"),
});
export const forgetPasswordSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const updateUserInfoSchema = Yup.object({
  userName: Yup.string()
    .min(2, "User Name is too short")
    .max(50, "User Name is too long")
    .required("User Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const registerSchema = Yup.object({
 
  firstName: Yup.string()
    .min(2, "First Name is too short")
    .max(50, "First Name is too long")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(2, "Last Name is too short")
    .max(50, "Last Name is too long")
    .required("Last Name is required"),
  usertype: Yup.string().required("User type is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

export const editRegisterSchema = Yup.object({
  userName: Yup.string()
    .min(2, "User Name is too short")
    .max(50, "User Name is too long")
    .required("User Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const AddTaskSchema = Yup.object({
  title: Yup.string()
    .min(2, "Task Title is too short")
    .max(50, "Task Title is too long")
    .required("Task Title is required"),
  // description:Yup.string().required("please fill description"),
  assignee: Yup.array()
    .required("Please select team members")
    .min(1, "Please select at least 1 members"),
  // owner:Yup.array().required("Please select owner"),
  priority: Yup.string().required("Please select priority field"),
  deadline: Yup.date().required("Please select deadline field"),
  // taskType: Yup.string().required("Please select taskType field"),
  // status: Yup.string().required("Please select status field"),
  // creationDate: Yup.date().required("Please select creationDate field"),
});

export const AddLabCaseSchema = Yup.object({
  title: Yup.string()
    .min(2, "Patient is too short")
    .max(50, "Patient is too long")
    .required("Patient Name is required"),
    labName: Yup.string().required("Please select Lab Name field"),
    sendDate: Yup.date().required("Please select Date field"),

});

export const AddLeadTaskSchema = Yup.object({
  title: Yup.string()
    .min(2, "User Name is too short")
    .max(50, "User Name is too long")
    .required("User Name is required"),
  // description:Yup.string().required("please fill description"),
  assignee: Yup.array()
    .required("Please select team members")
    .min(1, "Please select at least 1 members"),
  // owner:Yup.array().required("Please select owner"),
  priority: Yup.string().required("Please select priority field"),
  deadline: Yup.date().required("Please select deadline field"),
  // taskType: Yup.string().required("Please select taskType field"),
  // status: Yup.string().required("Please select status field"),
  // creationDate: Yup.date().required("Please select creationDate field"),
  lead: Yup.array().required("Please select lead field"),
  dateOfContact: Yup.date().required("Please select dateofContact field"),
  leadEmail: Yup.string()
    .email("Please Enter Valid Email")
    .required("Please Enter Lead Email"),
  LeadPhone: Yup.string().required("Please Enter Lead Phone"),
  // text: Yup.string().required("Please Enter Text"),
});
