import React, { useState } from "react";
import SubHeader from "../Components/Header Component/SubHeader";


import RegisterUserComponent from "../Components/User Component/RegisterUserComponent";
import RespoSideBar from "../Components/ResponsiveComponents/RespoSidebar";
import Table from "../Components/Tables/Table";

const AllUserScreen = ({userDetails,setShowScreen,
  showScreen}:any) => {
  const [user, setUser] = useState(false);
  const [handleOpen, setHandleOpen] = useState("");

  return (
    <div className="w-[100%] h-[100%] flex flex-col p-1 " 
  
    
    >
      {handleOpen === "" ? (
        <div className=" h-full " style={{ width: "100%" }}>
          <SubHeader
            heading={"All Users"}
            button={"Register User"}
            setHandleOpen={setHandleOpen}
          />
          <div
            className="w-full h-full flex justify-around py-2 pl-6 pr-4 gap-4"
            style={{ height: "calc(100% - 48px)" }}
          >
            <Table setUser={setUser} user={user} userDetails={userDetails} />
          </div>
        </div>
      ) : handleOpen === "Register" ? (
        <div  style={{ height: "100%", width: "calc(100%-90px)" }}>
          <RegisterUserComponent />
        </div>
      ) : (
        ""
      )}
         {showScreen.length > 0 ? (
        <RespoSideBar
          setShowSlider={setShowScreen}
          showSlider={showScreen}
          type={"sidebar"}
          direction={"left"}
        />
        
      ) : (
        ""
      )}
    </div>
    
  );
};

export default AllUserScreen;
