import { FaCheckCircle } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

interface propsType {
  setPopup:(a:boolean)=>void;
  setDeleteTask?:any;
  value?:any,
  setViewTask?:any;

}

const SucessPopups: React.FC<propsType> = ({ setPopup,setDeleteTask,value,setViewTask}) => {
  const nav=useNavigate();
  const handleClick=()=>{
    setPopup(false)
    setViewTask && setViewTask(false)
    setDeleteTask && setDeleteTask(false)
    if(value==="lead"){
      nav("/leadTask")
    }else if(value=="task"){
      nav("/dashboard")
    }else{
      nav("/user")
    }
  }
  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[9999]  bg-[#00000040]">
      <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
      <div
        className=" bg-[white] flex w-[350px] h-[90px] rounded-[10px] z-[100] p-4 flex-col "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <div className="flex  w-full h-[20px] justify-between items-center">
          <div className=" flex gap-4 items-center">
            <FaCheckCircle color={"#53A137"} size={18} />
            <span className="font-semibold">Successful</span>
          </div>
         <span onClick={handleClick}><RxCross2 />
            </span> 
        </div>
        <span className="mt-4 text-center text-[12px]">
          The user has been Registered successfuly!
        </span>
      </div>
    </div>
  );
};

export default SucessPopups;
