const FormikSelectPopupTwo = ({
  data,
  dataType = "string",
  selectValue,
  setSelectValue,
  setFieldValue,
  setCompanyScale,
  name,
}: any) => {
  const handleSelect = (value: string) => {
    setSelectValue(value);
    setFieldValue && setFieldValue(name, value);
    setCompanyScale && setCompanyScale(value);
  };

  return (
    <div
      className={`absolute z-30 flex flex-col bg-white bottom-[5px] translate-y-[100%] 
        ${name === "usertype" && "left-[110px]"}
        left-[55px] rounded-lg pt-4 pb-4 w-[70%] min-w-min border border-[#D7D7D7] gap-1 max-h-[60vh] overflow-auto mb-2`}
      style={{ boxShadow: "0px 4px 8px 0px #0000004D" }}
    >
      {data?.map((item: any) => (
        <div
          key={item}
          className={`flex flex-row gap-2 pl-5 pt-1 pb-1 pr-5 hover:bg-[#EEF2F7]  ${
            selectValue === item &&
            // "bg-[#EEEEEE]"
            "bg-[#EEF2F7] text-black"
          }`}
          onClick={() => handleSelect(item)}
        >
          <p className="flex gap-2 items-center font-primary font-normal text-sm capitalize">
            {item?.icon}
            {item === "todo" ? (
              <span className="  text-[12px] text-center px-3 py-[3px] bg-[#0496FF33] rounded-[7px] text-[#0496FF] font-semibold">
                {dataType === "string" ? item : item?.name}
              </span>
            ) : item === "inprogress" ? (
              <span className="  text-[12px] text-center px-3 py-[3px] bg-[#FFF1D6] rounded-[7px] text-[#EEAB2E] font-semibold">
                {dataType === "string" ? item : item?.name}
              </span>
            ) : item === "completed" ? (
              <span className="  text-[12px] text-center px-3 py-[3px] bg-[#3BB31133] rounded-[7px] text-[#3BB311] font-semibold">
                {dataType === "string" ? item : item?.name}
              </span>
            ) : (
              ""
            )}
            {dataType === "string" &&
            item !== "todo" &&
            item !== "inprogress" &&
            item !== "completed"
              ? item
              : item?.name}
          </p>
        </div>
      ))}
    </div>
  );
};

export default FormikSelectPopupTwo;
