import moment from "moment";
import React from "react";

interface propType {
  placeholder: string;
  className: string;
  fieldClass: string;
  placeholderStyle: object;
  type: string;
  name: string;
  fieldDisable?: Boolean;
  value: string | number;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: string;
  touched?:any;
  handleBlur?:any;
}

const DateField: React.FC<propType> = ({
  className,
  placeholder,
  placeholderStyle,
  type,
  name,
  fieldDisable,
  fieldClass,
  value,
  handleChange,
  errors,
  touched
}) => {
  return (
    <div className="flex mt-2 w-full ">   
    <div className={className + ` flex relative mb-2 `+ `${errors && " border-red-600 border-solid"}`}>
      <input
        className={
          fieldClass +
          " bg-transparent relative z-[1]  w-[100%] " +
          `${fieldDisable && "text-[#909090]"}`
          
        }
        type={type}
        placeholder={placeholder}
        name={name}
        value={value ?moment(value).format("YYYY-MM-DD") : ""}
        id={name}
        onChange={handleChange}

      />
  
       <div className="text-[#FF5050]  text-xs absolute top-6 left-4 capitalize font-medium pt-1">
          {errors && touched && typeof errors === "string" && errors}
        </div>
    </div>
    </div>
  );
};

export default DateField;
