import { SVGProps } from "react"
const ArrowSVG = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    {...props}
  >
    <path
      fill="#D65252"
      fillRule="evenodd"
      d="M5.398.372a.562.562 0 0 0-.796 0l-4.23 4.23a.562.562 0 0 0 0 .796l4.23 4.23a.562.562 0 0 0 .796 0l4.23-4.23a.562.562 0 0 0 0-.796L5.397.372ZM5.22 2.696a.312.312 0 0 0-.446.004L3.108 4.436a.313.313 0 0 0 .45.434l1.13-1.177v2.974a.313.313 0 0 0 .624 0V3.67l1.134 1.133a.312.312 0 1 0 .441-.442L5.221 2.696Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ArrowSVG