import { RxCross2 } from "react-icons/rx";
import { GiConfirmed } from "react-icons/gi";
import { FaTelegramPlane } from "react-icons/fa";
import toast from "react-hot-toast";

const ConfirmPopups = ({
  setConfirm,
  confirmValue,
  setConfirmValue,
  setRefetch,
  refetch,
  popup
}: any) => {
  const handleClick = () => {
    if (
      confirmValue.toLowerCase() !== "yes" &&
      confirmValue.toLowerCase() !== "no"
    ) {
      toast.error("please Enter the Right key Word", {
        position: "top-center",
      });
      setConfirmValue("");
    }
    setRefetch(refetch + 1);
  };

  return (
    <div className="flex justify-center  h-full fixed top-0 left-0 w-full z-[999]  bg-[#00000040]">
      <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
      <div
        className=" bg-[white] flex w-[350px] h-[200px] md:w-[320px]  rounded-[10px] z-[100] flex-col p-6 md:p-4"
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <div className="flex  w-full h-[20px] justify-between items-center py-4 ">
          <div className=" flex gap-2 items-center">
            <GiConfirmed color={"#53A137"} size={20} />

            <span className="font-semibold">Delete Task?</span>
          </div>
          <span>
            <RxCross2
              onClick={() => {
                setConfirm(false);
              }}
            />
          </span>
        </div>
        <div className="text-center flex flex-col w-full py-2  ">
          <span className="text-[14px]">
            {" "}
            Do you want to proceed with further actions?
          </span>
          <span className="text-[14px]">
            Please type "yes" to continue or "no" to cancel.
          </span>
        </div>
        <div className="w-full  flex gap-4 justify-center  py-2">
          <div className="w-full  border rounded-[5px] py-2 flex px-2 ">
            <input
              className="w-full h-full  outline-none px-2 font-normal"
              name="confirm"
              id="confirm"
              value={confirmValue}
              onChange={(e) => setConfirmValue(e.target.value)}
              autoComplete="off"
            />
            <FaTelegramPlane onClick={handleClick} size={20} color="#53A137" />
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default ConfirmPopups;
