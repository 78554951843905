import SubHeader from "../Components/Header Component/SubHeader";

const SureStayMaintScreen = () => {
  function setHandleOpen(a: string): void {
    throw new Error("Function not implemented.");
  }

  return (
    <div className=" w-[100%] h-[100%] flex  px-5">
      <div className="w-full h-full  ">
        <SubHeader
          heading="Add  Maint"
          button="Add  Maint"
          setHandleOpen={setHandleOpen}
        />
      </div>
    </div>
  );
};

export default SureStayMaintScreen;
