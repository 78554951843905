import NewHeader from "../Components/Header Component/NewHeader";
import AddLeadTask from "../Components/TaskComponents/AddLeadTask";

const AddLeadScreen = ({ refetch, setRefetch }: any) => {
  return (
    <div className="h-full w-full flex  ">
      <div className="h-full w-full flex flex-col gap-2 py-4 px-10 md:px-6">
        <NewHeader heading="Add Lead Task" />
        <AddLeadTask refetch={refetch} setRefetch={setRefetch} />
      </div>
    </div>
  );
};

export default AddLeadScreen;
