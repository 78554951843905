import { SVGProps } from "react"

const AddTaskSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={31}
    fill="none"
    {...props}
  >
    <g stroke="#B8B9C8" strokeLinecap="round" strokeWidth={2.323} opacity={0.6}>
      <rect width={9.033} height={9.033} x={3.871} y={3.871} rx={1.29} />
      <rect width={9.033} height={9.033} x={3.871} y={18.065} rx={1.29} />
      <rect width={9.033} height={9.033} x={18.065} y={3.871} rx={1.29} />
      <rect width={9.033} height={9.033} x={18.065} y={18.065} rx={1.29} />
    </g>
  </svg>
)
export default AddTaskSVG
