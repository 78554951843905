import moment from "moment";
import React from "react";
import { useAppSelector } from "../Redux/Hooks";

const CommentDetails = ({ value }: any) => {
  const user = useAppSelector((state) => state?.user?.user);

  return (
    <>
      <div className="w-full py-4 ">
        <span className="font-semibold">Comment Section</span>
      </div>
      {value.length ? (
        value.map((item: any, index: any) => {
          return (
            <div key={index} className="flex flex-col gap-4  py-4">
              <div className="flex gap-4">
                <div className="w-[35px] h-[35px] flex justify-center items-center rounded-full text-white bg-[#426D85]">
                  {user?.name[0]?.toUpperCase() + "" + user?.name[1]}
                </div>
                <div className="flex flex-col w-full">
                  <span className="font-semibold text-[14px]">
                    {user?.name?.toUpperCase()}
                  </span>
                  <div className="flex items-center justify-between">
                    <span className="font-normal">{item?.comment}</span>
                  </div>
                  <p className="text-[#909090] text-[10px]">
                    Commented :{" "}
                    <span>
                      {moment(item?.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-[16px] italic text-[#A0A0A0] font-semibold">No Comment is Added by User </div>
      )}
    </>
  );
};

export default CommentDetails;
