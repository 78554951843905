import { SVGProps } from "react"

const MediumStatusSvg = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#F5C400"
        fillRule="evenodd"
        d="M.67 8.283a1.013 1.013 0 0 0 0 1.432l7.614 7.614a1.012 1.012 0 0 0 1.433 0l7.612-7.614a1.012 1.012 0 0 0 0-1.432L9.717.67a1.012 1.012 0 0 0-1.433 0L.67 8.283Zm4.183.319a.563.563 0 0 0 .008.803l3.125 3a.563.563 0 0 0 .78-.812L6.649 9.562H12a.563.563 0 1 0 0-1.125H6.608l2.04-2.04a.562.562 0 1 0-.795-.795l-3 3Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default MediumStatusSvg