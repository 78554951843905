import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { readNotification, userNotification } from "../../Slices/UserSlice";
import { useOutSideCheck } from "../customize state/useOutSideCheck";
import moment from "moment";
import Loader from "../../assets/image/svg/Loader";

const Notification = ({ setNotification, notification }: any) => {
  const wrapperRef = useRef(null);
  useOutSideCheck(wrapperRef, setNotification, notification);
  const dispatch = useAppDispatch();
  const [notificationData, setNotificationData] = useState([]);
  const [unread, SetUnread] = useState<any>([]);
  // const [refetch, setRefetch] = useState(1);
  const [loader, setLoader] = useState(false);
  const [notify, setNotify] = useState("");

  const user = useAppSelector((state) => state.user.user);


  const handleNotification = () => { // api for notification
    setLoader(true);
    dispatch(userNotification())
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setNotificationData(res.allNotification);
          const tempUnread =res.allNotification?.length ? res.allNotification?.filter((item: any) => {
            if (!item?.isRead.length) {
              return item
            }
          }) : []
        SetUnread([...tempUnread]);
          setLoader(false);
        }
      })
      .catch((error: any) => {
        toast.error(error?.msg);
        setLoader(false);
      });
  };
 

  useEffect(() => {
    handleNotification();
  }, []);



  const handleReadNotification = (id: any) => { // api for read notification
    const payload = {
      notification_id: id,
    };
    dispatch(readNotification(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          
          handleNotification()
        }
      })
      .catch((error: any) => {
        toast.error(error?.msg);
      });
  };

  
  return (
 
    <div
      className="h-[85vh] absolute top-[35px] right-[4rem] md:right-0 md:w-[350px] z-[999] bg-white w-[400px] border-none rounded-[10px] overflow-auto"
      style={{ boxShadow: "0px 1.8px 8.98px 0px #0000000D" }}
      ref={wrapperRef}
    >
      <div className="border-b border-[#EAEAEA] py-5 px-6">
        <span className="font-medium text-[20px]">Notification</span>
      </div>
      <div className="flex justify-between py-3 border-b border-[#EAEAEA] px-6">
        <div className="flex gap-4 items-center">
          <span
            onClick={() => setNotify("")}
            className="text-[14px] text-center text-[#3E5F71] cursor-pointer"
            style={notify==="" ? {borderBottom:"1px solid #3E5F71"} : {} }
          >
            All
          </span>
          <span
            onClick={() => setNotify("unread")}
            className="text-[12px] text-center text-[#BABABA] cursor-pointer"
            style={notify==="unread" ? {borderBottom:"1px solid #3E5F71"} : {} }
          >
            Unread ({unread.length})
          </span>
        </div>
        <span className="font-medium text-[#0094FF] text-[12px]">
          Mark all as read
        </span>
      </div>

      {(notify === "unread" ? unread : notificationData).length ? (
        (notify === "unread" ? unread : notificationData).map(
          (item: any, index: any) => {
          
            return (
              <div
                key={index}
                onClick={() =>
                  !item?.isRead?.length && handleReadNotification(item?._id)
                }
                className="flex gap-4 py-4 px-4 cursor-pointer"
                style={
                  !item?.isRead?.length ? { backgroundColor: "#E2E8F0" } : {}
                }
              >
                <div className="w-[35px] h-[35px] rounded-full flex justify-center items-center text-white bg-[#3E5F71] px-2">
                  {user?.name[0]?.toUpperCase() +
                    "" +
                    user?.name[1]?.toUpperCase()}
                </div>
                <div className="flex flex-col">
                  <p className="text-[14px]">
                    <span className="font-semibold">
                      {user?.name.toUpperCase()}{" "}
                    </span>
                    Added
                    {" " + item?.message}
                  </p>
                  <span className="text-[#909090] text-[12px]">
                    {moment(item?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                </div>
              </div>
            );
          }
        )
      ) : (
        <div className="flex justify-center items-center h-[30%]">
          No Notification Found
        </div>
      )}

      {loader && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Notification;
