import { SVGProps } from "react"


const DeleteSVG = (props:SVGProps<SVGSVGElement>) => {
  return (
   <svg
   xmlns="http://www.w3.org/2000/svg"
   width={21}
   height={24}
   fill="none"
   {...props}
 >
   <path
     fill="#D65252"
     fillRule="evenodd"
     d="m18.42 4.923-.989 16.76a2.457 2.457 0 0 1-.771 1.646c-.459.431-1.065.671-1.696.671H6.036c-.63 0-1.237-.24-1.696-.67a2.458 2.458 0 0 1-.771-1.647l-.987-16.76H0v-1.23a.614.614 0 0 1 .618-.616h19.764a.619.619 0 0 1 .618.615v1.231h-2.58ZM8.03 0h4.94a.619.619 0 0 1 .618.615v1.231H7.412V.616A.614.614 0 0 1 8.029 0ZM6.793 8l.618 11.077h1.853L8.77 8H6.794Zm5.559 0-.618 11.077h1.853L14.206 8h-1.853Z"
     clipRule="evenodd"
   />
 </svg>
  )
}

export default DeleteSVG