import SubHeader from "../Components/Header Component/SubHeader";
import { useState } from "react";
import ArrowSVG from "../assets/image/svg/ArrowSVG";
import ViewSingleTask from "../Components/TaskComponents/ViewSingleTask";
import { Droppable } from "react-beautiful-dnd";
import RespoAllTask from "../Components/ResponsiveComponents/RespoAllTask";
import CartTask from "../Components/TaskComponents/CartTask";

interface PropsType {
  refetch: any;
  windowWidth: any;
  showScreen: any;
  setShowScreen: any;
  setTaskData: any;
  completed: any;
  inprogress: any;
  todo: any;
  taskData: any;
  setRefetch: any;
}

const AllLeadTask: React.FC<PropsType> = ({
  windowWidth,
  showScreen,
  setShowScreen,
  setTaskData,
  completed,
  inprogress,
  todo,
  taskData,
  setRefetch,
  refetch,
}) => {
  const [viewTask, setViewTask] = useState(false);
  const [handleOpen, setHandleOpen] = useState("");
  const [taskDetail, setTaskDetail] = useState<any>({});
  const [size, setSize] = useState<any>("medium");

  const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSize(event.target.value);
  };
  return (
    <>
      {windowWidth < 700 ? (
        <RespoAllTask
          button={"Add Lead Task"}
          todo={todo}
          size={size}
          inprogress={inprogress}
          completed={completed}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
          setTaskDetail={setTaskDetail}
          setViewTask={setViewTask}
          taskDetail={taskDetail}
          viewTask={viewTask}
          setRefetch={setRefetch}
          refetch={refetch}
          name={"All Lead Tasks"}
        />
      ) : (
        <div className="w-[100%] h-[100%] flex  px-5">
          <div className="w-full h-full  ">
            <SubHeader
              heading="All Lead Tasks"
              button="Add Lead Task"
              setHandleOpen={setHandleOpen}
            />

            <div className="w-full items-center flex justify-end mt-3 pr-5 ">
              <label htmlFor="size" className="mr-2">
                Select Cart Size:
              </label>
              <select
                id="size"
                value={size}
                onChange={handleSizeChange}
                className="px-2 py-1 border rounded"
              >
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </select>
            </div>
            <div
              className=" w-full  flex gap-2  py-4  justify-between  "
              style={{ height: "calc(100% - 48px)" }}
            >
              <Droppable droppableId="TodosList">
                {(provided) => (
                  <div
                    className="w-1/3 h-full bg-[#E2E8F0] p-2 md:mt-[40px] rounded-[15px]"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className=" h-full flex  justify-center flex-wrap overflow-auto">
                      <div className="w-full h-full flex flex-col gap-4 px-2 py-3">
                        <div className="w-full flex px-2">
                          <span
                            className="text-[16px]  font-semibold text-center px-5 py-1 bg-[#CDEAFF] rounded-[7px] text-[#0496FF] "
                            style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                          >
                            TO DO ({todo?.length})
                          </span>
                        </div>
                        <div className="flex flex-col py-1 px-2">
                          <span className="font-medium text-[14px]">
                            Task Assigned
                          </span>
                          <span className="text-[#505050] text-[13px]">
                            The tasks are assigned to team members.
                          </span>
                        </div>
                        <div className="h-full flex flex-col gap-2 w-full overflow-auto items-center ">
                          {todo?.map((item: any, index: number) => {
                            return (
                              <CartTask
                                setViewTask={setViewTask}
                                setTaskDetail={setTaskDetail}
                                icon={<ArrowSVG />}
                                item={item}
                                index={index}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              <div className="h-full border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <Droppable droppableId="InProgressList">
                {(provided) => (
                  <div
                    className="w-1/3 h-full bg-[#E2E8F0] p-2 rounded-[15px]"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="  h-full flex  justify-center flex-wrap overflow-auto  ">
                      <div className="w-full h-full flex flex-col gap-4  px-2 py-3   ">
                        <div className="w-full flex  px-2">
                          <span
                            className="text-[16px] font-semibold text-center px-5 py-1 bg-[#EADDD5] rounded-[7px] text-[#EE7E2E] "
                            style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                          >
                            IN PROGRESS ({inprogress?.length})
                          </span>
                        </div>
                        <div className="flex flex-col  py-1 px-2">
                          <span className="font-medium text-[14px]">
                            Task Started
                          </span>
                          <span className="text-[#505050] text-[13px]">
                            The team started working on the tasks.
                          </span>
                        </div>
                        <div className="h-full flex flex-col gap-2 w-full overflow-auto items-center">
                          {inprogress?.map((item: any, index: number) => {
                            return (
                              <CartTask
                                setViewTask={setViewTask}
                                setTaskDetail={setTaskDetail}
                                icon={<ArrowSVG />}
                                item={item}
                                size={size}
                                index={index}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>

              <Droppable droppableId="CompletedList">
                {(provided) => (
                  <div
                    className="w-1/3 h-full bg-[#E2E8F0] p-2 rounded-[15px] "
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="  h-full flex  justify-center flex-wrap overflow-auto  ">
                      <div className="w-full h-full flex flex-col gap-4  px-2 py-3   ">
                        <div className="w-full flex  px-2">
                          <span
                            className="text-[16px] font-semibold text-center px-5 py-1 bg-[#D8F0CF] rounded-[7px] text-[#3BB311] "
                            style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                          >
                            COMPLETED ({completed?.length})
                          </span>
                        </div>
                        <div className="flex flex-col  py-1 px-2">
                          <span className="font-medium text-[14px]">
                            Task Completed
                          </span>
                          <span className="text-[#505050] text-[13px]">
                            The tasks are Completed by team members.
                          </span>
                        </div>
                        <div className="h-full flex flex-col gap-2 w-full overflow-auto rounded-[15px] items-center">
                          {completed?.map((item: any, index: number) => {
                            return (
                              <CartTask
                                setViewTask={setViewTask}
                                setTaskDetail={setTaskDetail}
                                icon={<ArrowSVG />}
                                item={item}
                                index={index}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>

          {viewTask && (
            <ViewSingleTask
              setViewTask={setViewTask}
              taskDetail={taskDetail}
              setRefetch={setRefetch}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AllLeadTask;
