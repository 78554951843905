import { useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const RespoSelectTask = ({ setShowTask, button,name }: any) => {
  const nav = useNavigate();
  
  const detail = [
    {
      name: "todo",

      heading: "Task Assigned",
      desc: "The tasks are assigned to team members.",
    },
    {
      name: "inprogress",
      heading: "Task Started",
      desc: "The team started working on the tasks.",
    },
    {
      name: "completed",
      heading: "Task Completed",
      desc: "The task is completed by the team.",
    },
  ];

  const handleClick = (item: any) => {
   
    setShowTask(item);
  };
  const handleNav = (item: any) => {
    if (item === "Add Task") {
      nav("/AddTask");
    } else {
      nav("/AddLeadTask");
    }
  };

  return (
    <div className="w-full h-full">
      <div className="font-medium text-[20px] pt-8 pb-4">{name}</div>

      <div
        className="w-full flex gap-4 overflow-x-auto no-scrollbar mt-2 mb-4 "
        style={{ boxShadow: "0px 8px 25px 0px #00000014" }}
      >
        <>
          {detail?.map((item, index) => (
            <div
              onClick={() => handleClick(item?.name)}
              key={index}
              className="min-w-[180px]  bg-[white] p-[15px] rounded-[10px] "
              style={{ boxShadow: "0px 8px 25px 0px #00000014" }}
            >
              <div className="w-full flex justify-between pb-4 items-center">
                {item?.name === "todo" ? (
                  <span
                    className="text-[14px] font-semibold text-center py-1 px-2  bg-[#CDEAFF] rounded-[7px] text-[#0496FF]"
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    TO DO
                  </span>
                ) : item?.name === "inprogress" ? (
                  <span
                    className="text-[14px] font-semibold text-center py-1 px-2 bg-[#EADDD5] rounded-[7px] text-[#EE7E2E] "
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    IN PROGRESS
                  </span>
                ) : (
                  <span
                    className="text-[14px] font-semibold text-center py-1 px-2 bg-[#D8F0CF] rounded-[7px] text-[#3BB311] "
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    COMPLETED
                  </span>
                )}
                <IoIosArrowForward />
              </div>
              <div className="text-[12px] font-semibold">{item?.heading}</div>
              <div className="text-[#A0A0A0] font-normal text-[10px]">
                {item?.desc}
              </div>
            </div>
          ))}
        </>
      </div>

      <div
        onClick={() => handleNav(button)}
        className="w-full bg-[#3D5765] rounded-[15px] py-[10px] mt-10"
        style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
      >
        <button className="w-full font-semibold text-[white] text-[20px]">
          {button}
        </button>
      </div>
    </div>
  );
};

export default RespoSelectTask;
