import { useState } from "react";

import { useNavigate } from "react-router-dom";
import Folder from "./Folder";

const Documents = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col justify-between w-full h-full py-2 overflow-auto relative">
      <div className="flex flex-row flex-wrap w-full  gap-6">
        <Folder />
        <Folder />
        <Folder />
        <Folder />
        <Folder />
        <Folder />
      </div>
    </div>
  );
};

export default Documents;
