import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { UpdateUser } from "../../Slices/UserSlice";
import toast from "react-hot-toast";
import CrossSVG from "../../assets/image/svg/CrossSVG";
import { Form, Formik } from "formik";
import { updatePasswordSchema } from "../../Validations/FormikValidation";
import InputField from "../Field Components/InputField";
import SucessPopups from "../../Popups/SucessPopups";
import Loader from "../../assets/image/svg/Loader";


interface propsType {
  setPassword: any;
}

const UpdatePassword: React.FC<propsType> = ({ setPassword }) => {
  const user = useAppSelector((state) => state.user.user);
  const [update, setUpdate] = useState(false);
  const [loader,setLoader]=useState(false);
  const dispatch = useAppDispatch();
  const handleUpdateFunc = (newPassword: string, confirmPassword: string) => {
    setLoader(true);
    const payload = {
      _id:user?._id,
      name: user?.name,
      email: user?.email,
      userType:user?.userType,
      password: newPassword,
      newPassword: confirmPassword,
      userStatus:user?.userStatus,
    };
    dispatch(UpdateUser(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setLoader(false);
          setPassword(false);
          toast.success(res?.msg, {
            position: "top-right",
          });
        }
      })
      .catch((error)=>{
        setLoader(false);
        toast.error(error,{
          position:"top-right",
        });
      })
  };
  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[999] bg-[#00000040]">
      <div
        onClick={() => setPassword(false)}
        className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"
      ></div>
      <div className="bg-white h-auto w-[33%] md:w-[90%] z-20 rounded-[20px] p-[35px] relative">
        <div className="flex justify-between items-center mb-6 ">
          <p className="font-semibold text-[18px]">Update Password</p>
          <div
            onClick={() => setPassword(false)}
            className="flex items-center justify-center w-8 m-[4px] h-8  cursor-pointer"
          >
            <CrossSVG />
          </div>
        </div>

        <Formik
          initialValues={{ newPassword: "", confirmPassword: "" }}
          validationSchema={updatePasswordSchema}
          onSubmit={(values) => {
            handleUpdateFunc(values.newPassword, values.confirmPassword);
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="flex w-full justify-center  ">
              <Form
                onSubmit={handleSubmit}
                className=" flex flex-col gap-4 w-full"
              >
                <label className="font-semibold text-[14px]">
                  New Password <span className="text-[#FF2F2F]">*</span>
                </label>
                <InputField
                  className="w-full bg-white rounded-lg border border-[#DADADA]"
                  fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                  placeholderStyle={{ top: "8px", left: "18px" }}
                  type="password"
                  name="newPassword"
                  placeholder="Enter Password"
                  value={values?.newPassword}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.newPassword ? errors.newPassword : undefined}
                />

                <label className="font-semibold text-[14px]">
                  Confirm New Password <span className="text-[#FF2F2F]">*</span>
                </label>
                <InputField
                  className="w-full bg-white rounded-lg border border-[#DADADA]"
                  fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                  placeholderStyle={{ top: "8px", left: "18px" }}
                  type="password"
                  name="confirmPassword"
                  placeholder="Re-Enter Password"
                  value={values?.confirmPassword}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={
                    errors.confirmPassword ? errors.confirmPassword : undefined
                  }
                />

                <div className="flex justify-center gap-4 mt-3 ">
                  <button onClick={() => setPassword(false)}
                    type="button"
                    className="w-1/2 py-2  border border-[#A0A0A0] rounded-[10px] font-normal"
                  >
                    Cancel
                  </button>
                  <button
                  
                    type="submit"
                    className="w-1/2 rounded-[10px]  bg-[#3D5765] font-normal text-white"
                  >
                   {loader ? (
                      <div className="flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
      {update && <SucessPopups setPopup={setUpdate}/>}
    </div>
  );
};

export default UpdatePassword;
