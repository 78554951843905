import { SVGProps } from "react"

const HamIconSVG = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={19}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2}
      d="M29 1H1m15.75 8.5H1M29 18H1"
    />
  </svg>
)
export default HamIconSVG 
