import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";

import SubHeader from "../Components/Header Component/SubHeader";
import ViewSingleTask from "../Components/TaskComponents/ViewSingleTask";
import RespoAllTask from "../Components/ResponsiveComponents/RespoAllTask";

import Contesting from "../Components/QualityDisputesComponents/Contesting";
import WonClosed from "../Components/QualityDisputesComponents/WonClosed";
import NewDispute from "../Components/QualityDisputesComponents/NewDispute";
import WonPartial from "../Components/QualityDisputesComponents/WonPartial";
import LostClosed from "../Components/QualityDisputesComponents/LostClosed";

type cartSize = "small" | "medium" | "large";

interface PropsType {
  refetch: any;
  windowWidth: any;
  showScreen: any;
  setShowScreen: any;
  setTaskData: any;
  completed: any;
  inprogress: any;
  todo: any;
  taskData: any;
  setRefetch: any;
}

const QualityDisputeScreen: React.FC<PropsType> = ({
  windowWidth,
  showScreen,
  setShowScreen,
  setTaskData,
  completed,
  inprogress,
  todo,
  taskData,
  setRefetch,
  refetch,
}) => {
  const [viewTask, setViewTask] = useState(false);
  const [handleOpen, setHandleOpen] = useState("");
  const [taskDetail, setTaskDetail] = useState<any>({});
  const [size, setSize] = useState<cartSize>("medium");
  const [myTask, setMyTask] = useState<any>("false");

  return (
    <>
      {windowWidth < 700 ? (
        <RespoAllTask
          button={"Add Lead Task"}
          todo={todo}
          myTask={myTask}
          size={size}
          inprogress={inprogress}
          completed={completed}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
          setTaskDetail={setTaskDetail}
          setViewTask={setViewTask}
          taskDetail={taskDetail}
          viewTask={viewTask}
          setRefetch={setRefetch}
          refetch={refetch}
          name={"All Lead Tasks"}
        />
      ) : (
        <div className="w-[100%] h-[100%] flex  px-5">
          <div className="w-full h-full  ">
            <SubHeader
              heading="Add New Disputes"
              button="Add New Disputes"
              setHandleOpen={setHandleOpen}
            />

            <div
              className=" w-full  flex gap-2  py-4  justify-between overflow-x-auto"
              style={{ height: "calc(100% - 48px)" }}
            >
              <NewDispute // this component is New lead
                todo={todo}
                size={size}
                myTask={myTask}
                setSize={setSize}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
              />

              <div className="h-full border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <Contesting // this component is Following up lead
                completed={completed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                size={size}
                myTask={myTask}
                setSize={setSize}
              />

              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <WonPartial // this component is complete reward
                completed={completed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                size={size}
                myTask={myTask}
                setSize={setSize}
              />

              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <WonClosed // this component is completed lead
                completed={completed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                size={size}
                myTask={myTask}
                setSize={setSize}
              />
              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <LostClosed // this component is completed lead
                completed={completed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                size={size}
                myTask={myTask}
                setSize={setSize}
              />
            </div>
          </div>

          {viewTask && (
            <ViewSingleTask
              setViewTask={setViewTask}
              taskDetail={taskDetail}
              setRefetch={setRefetch}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </>
  );
};

export default QualityDisputeScreen;
