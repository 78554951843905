import { SVGProps } from "react"

const AddMemberSVG = (props:SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#BABBCA"
      d="M13.78 11.864a4.478 4.478 0 0 1-2.357-.69 4.046 4.046 0 0 1-1.551-1.795 3.733 3.733 0 0 1-.224-2.296c.17-.77.579-1.474 1.177-2.027a4.369 4.369 0 0 1 2.184-1.077 4.547 4.547 0 0 1 2.457.233c.776.303 1.44.813 1.906 1.466.467.654.716 1.421.716 2.207-.008 1.06-.466 2.074-1.274 2.82a4.457 4.457 0 0 1-3.035 1.16Zm0-6.563a2.906 2.906 0 0 0-1.526.456c-.45.289-.797.695-1 1.168-.202.473-.25.99-.14 1.489.113.498.38.955.769 1.312.388.357.882.599 1.417.695.536.096 1.09.043 1.593-.154a2.734 2.734 0 0 0 1.235-.952c.302-.423.464-.921.464-1.43a2.529 2.529 0 0 0-.835-1.834 2.905 2.905 0 0 0-1.978-.75Z"
    />
    <path
      stroke="#BABBCA"
      strokeWidth={0.898}
      d="M13.777 4.853h-.005c-.627.01-1.24.191-1.76.526-.522.336-.93.81-1.17 1.369a2.871 2.871 0 0 0-.165 1.764c.133.59.448 1.127.902 1.544a3.286 3.286 0 0 0 1.642.807 3.4 3.4 0 0 0 1.836-.178 3.183 3.183 0 0 0 1.437-1.109 2.911 2.911 0 0 0 .547-1.691V7.88a2.978 2.978 0 0 0-.98-2.16 3.354 3.354 0 0 0-2.284-.867Zm-7.344 10.46V15.3a.722.722 0 0 1 .107-.4.835.835 0 0 1 .327-.302 15.927 15.927 0 0 1 9.092-1.408l.024.513a15.497 15.497 0 0 0-8.693 1.296l-.258.12V17.8a.227.227 0 0 1-.077.165.328.328 0 0 1-.222.084.327.327 0 0 1-.223-.084.227.227 0 0 1-.077-.165v-2.486Zm7.35-3.898a4.03 4.03 0 0 1-2.12-.62 3.598 3.598 0 0 1-1.38-1.595 3.284 3.284 0 0 1-.197-2.02c.15-.677.51-1.302 1.043-1.794a3.92 3.92 0 0 1 1.96-.966 4.098 4.098 0 0 1 2.214.21c.698.272 1.29.73 1.704 1.309.413.579.632 1.255.632 1.944-.007.93-.41 1.828-1.129 2.493a4.008 4.008 0 0 1-2.728 1.04Z"
    />
    <path
      stroke="#A9ABBD"
      strokeWidth={0.898}
      d="M19.225 15.837v.449H24.686c.089 0 .168.033.222.084.053.05.077.11.077.165v6.982a.227.227 0 0 1-.077.166.327.327 0 0 1-.222.083h-11.97a.327.327 0 0 1-.222-.083.227.227 0 0 1-.076-.166v-6.982c0-.056.023-.116.076-.165a.327.327 0 0 1 .223-.084h5.91v-1.482c0-.056.023-.116.076-.166a.328.328 0 0 1 .223-.084c.089 0 .168.034.222.084.053.05.077.11.077.166v1.033Zm4.713 7.43h.448V16.786H19.224v.735a.227.227 0 0 1-.076.165.327.327 0 0 1-.222.084.327.327 0 0 1-.223-.084.227.227 0 0 1-.077-.165v-.735h-5.61V23.268h10.922Z"
    />
    <path
      stroke="#B4B6C5"
      strokeWidth={0.898}
      d="M7.766 9.48c.05.167.108.33.174.49a13.11 13.11 0 0 0-5.137 1.207l-.26.12v2.312a.227.227 0 0 1-.076.166.327.327 0 0 1-.223.083.328.328 0 0 1-.223-.083.227.227 0 0 1-.076-.166v-2.163a.66.66 0 0 1 .108-.356.77.77 0 0 1 .306-.268A13.776 13.776 0 0 1 7.766 9.48Zm-.24-1.587c.002.122.009.243.02.364a3.432 3.432 0 0 1-1.632-.895 2.991 2.991 0 0 1-.902-2.06c.014-.624.23-1.231.622-1.744a3.372 3.372 0 0 1 1.6-1.131 3.65 3.65 0 0 1 2.022-.09c.459.11.884.309 1.25.577-.162.092-.319.19-.472.295a2.92 2.92 0 0 0-1.647-.508 2.848 2.848 0 0 0-1.906.631 2.547 2.547 0 0 0-.937 1.729 2.495 2.495 0 0 0 .6 1.862c.36.42.844.717 1.381.861v.11Zm16.602 3.285A12.992 12.992 0 0 0 19.6 9.996c.068-.165.129-.334.18-.506 1.674.146 3.301.595 4.79 1.324a.77.77 0 0 1 .308.27c.071.111.107.236.107.36v2.165a.227.227 0 0 1-.077.166.327.327 0 0 1-.222.083.327.327 0 0 1-.223-.083.227.227 0 0 1-.076-.166V11.3l-.26-.121ZM21.22 7.17c-.318.38-.727.686-1.193.895.003-.062.004-.124.005-.186v-.003c0-.13-.005-.26-.015-.39.342-.199.636-.468.86-.79.286-.414.443-.9.449-1.398v-.014a2.536 2.536 0 0 0-.433-1.361 2.745 2.745 0 0 0-1.102-.946 2.94 2.94 0 0 0-2.59.032 6.446 6.446 0 0 0-.54-.293c.169-.102.347-.19.534-.264.421-.165.875-.25 1.333-.25h.002a3.527 3.527 0 0 1 2.399.91c.632.583.986 1.367.995 2.181a2.965 2.965 0 0 1-.704 1.877Zm-4.457 12.898h3.56v.08h-3.56v-.08Z"
    />
  </svg>
)
export default  AddMemberSVG