import { RiDeleteBin6Fill } from "react-icons/ri";

const MiniSelectPopup = ({
   data,
   setPopupShow,
   selectValue,
   setSelectValue,
 }: any) => {
   const handleSelect = (e: any, value: string) => {
     e.stopPropagation();
     setSelectValue(value);
     setPopupShow && value !== "Alloted To" && setPopupShow(false);
   };
 
   return (
     <div
       className={`absolute z-30 flex flex-col bg-white bottom-[3px] translate-y-[100%] translate-x-[100%] max-771:translate-x-[0%] right-[100px] rounded-lg  w-full min-w-min border border-[#D7D7D7] `}
       style={{ boxShadow: "0px 4px 8px 0px #0000004D" }}
     >
       {data?.map((item: string) => (
         <div
           key={item}
           className={`flex flex-row gap-2 pl-3 pr-8 hover:bg-[#F4F7FB] hover:text-[#FF0000] hover:rounded-lg
           ${item === "Delete" && "text-[black]"}
           ${
             selectValue === item &&
             // "bg-[#EEEEEE]"
             "bg-primary text-[#fff]"
           }
           `}
           onClick={(e) => handleSelect(e, item)}
         >
           <p
             className="flex flex-row items-center gap-1 my-[6px] font-normal text-sm capitalize text-nowrap  "
             style={{ fontSize: "12px" }}
           >
             {/* {item === "Rename" && <MdEdit />} */}
             {item === "Delete" && <RiDeleteBin6Fill />}
             {item}
           </p>
         </div>
       ))}
     </div>
   );
 };
 
 export default MiniSelectPopup;